import { apiUrl } from '@/env';
import axios from 'axios';
function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
}
axios.interceptors.request.use((config) => {
    if (!config.url.includes('viacep')) {
        config.headers.tmz = -(new Date().getTimezoneOffset() / 60);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
export const api = {
    async logInGetToken(username, password, admin, gToken, codeTwoFA, company_id) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('gToken', gToken);
        params.append('admin', admin.toString());
        params.append('company_id', company_id.toString());
        params.append('code_two_fa', codeTwoFA.toString());
        return axios.post(`${apiUrl}/api/v1/login/access-token/`, params);
    },
    async logInAlunoGetToken(username, password, gToken, company_id) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('gToken', gToken);
        params.append('company_id', company_id.toString());
        return axios.post(`${apiUrl}/api/v1/login/access-token/aluno/`, params);
    },
    async logInVerify2fa(company_id, username, password, otp_code, user_id) {
        const params = new URLSearchParams();
        params.append('company_id', company_id);
        params.append('username', username);
        params.append('password', password);
        params.append('otp_code', otp_code);
        params.append('user_id', user_id);
        return axios.post(`${apiUrl}/api/v1/login/verify/`, params);
    },
    async getActions(token) {
        return axios.get(`${apiUrl}/api/v1/actions/`, authHeaders(token));
    },
    async getActionsDefault(token, payload) {
        return axios.get(`${apiUrl}/api/v1/actions/default/${payload.cargo_id}/${payload.departamento_id}/`, authHeaders(token));
    },
    async updateActionsDefault(token, payload) {
        return axios.put(`${apiUrl}/api/v1/actions/default/${payload.action_id}/`, payload, authHeaders(token));
    },
    async getMe(token) {
        return axios.get(`${apiUrl}/api/v1/users/me/`, authHeaders(token));
    },
    async updateMe(token, data) {
        return axios.put(`${apiUrl}/api/v1/users/me/`, data, authHeaders(token));
    },
    async getUsers(token, somenteAtivo) {
        return axios.get(`${apiUrl}/api/v1/users/?somente_ativo=${somenteAtivo}`, authHeaders(token));
    },
    async getUser(token, userId) {
        return axios.get(`${apiUrl}/api/v1/users/${userId}/`, authHeaders(token));
    },
    async userVerificaDisponibilidade(token, payload) {
        return axios.get(`${apiUrl}/api/v1/users/verifica-disponibilidade/${payload.id}/?cpf=${payload.cpf}&email=${payload.email}`, authHeaders(token));
    },
    async updateUser(token, data) {
        return axios.put(`${apiUrl}/api/v1/users/${data.id}/`, data, authHeaders(token));
    },
    async updatePassword(token, data) {
        return axios.put(`${apiUrl}/api/v1/users/${data.id}/update-password/`, data, authHeaders(token));
    },
    async updateCurrentUserPassword(token, data) {
        return axios.put(`${apiUrl}/api/v1/users/me/update-password/`, data, authHeaders(token));
    },
    async updateUserLoginOtp(token) {
        return axios.put(`${apiUrl}/api/v1/users/activate_disable_2fa/`, {}, authHeaders(token));
    },
    async createUser(token, data) {
        return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
    },
    async passwordRecovery(email) {
        return axios.post(`${apiUrl}/api/v1/password-recovery/${email}/`);
    },
    async resetPassword(token, password, email, user_id) {
        return axios.post(`${apiUrl}/api/v1/reset-password/`, {
            new_password: password,
            email,
            user_id
        }, authHeaders(token));
    },
    async creatUserTool(token, data) {
        return axios.post(`${apiUrl}/api/v1/user-tools/`, data, authHeaders(token));
    },
    async getToolsByUserId(token, userId) {
        return axios.get(`${apiUrl}/api/v1/user-tools/${userId}/`, authHeaders(token));
    },
    async getActionsByUserId(token, userId) {
        return axios.get(`${apiUrl}/api/v1/user-actions/${userId}/`, authHeaders(token));
    },
    async updateUserTools(token, data) {
        return axios.put(
        // @ts-ignore
        `${apiUrl}/api/v1/user-tools/${data.id}/`, data, authHeaders(token));
    },
    async getUserTools(token) {
        return axios.get(`${apiUrl}/api/v1/user-tools/me/`, authHeaders(token));
    },
    async getUserActions(token) {
        return axios.get(`${apiUrl}/api/v1/user-actions/me/`, authHeaders(token));
    },
    async getToolsSchool(token) {
        return axios.get(`${apiUrl}/api/v1/tools/school/`, authHeaders(token));
    },
    async getTools(token) {
        return axios.get(`${apiUrl}/api/v1/tools/`, authHeaders(token));
    },
    async getToolsDefault(token, payload) {
        return axios.get(`${apiUrl}/api/v1/tools/default/${payload.cargo_id}/${payload.departamento_id}/`, authHeaders(token));
    },
    async updateToolsDefault(token, payload) {
        return axios.put(`${apiUrl}/api/v1/tools/default/${payload.tool_id}/`, payload, authHeaders(token));
    },
    async createCompany(token, data) {
        return axios.post(`${apiUrl}/api/v1/companies/`, data, authHeaders(token));
    },
    async getCompany(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/companies/${companyId}/`, authHeaders(token));
    },
    async getCompanyDadosUnidade(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/companies/${companyId}/dados-unidade`, authHeaders(token));
    },
    async gerarContrato(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/companies/${companyId}/contrato/`, authHeaders(token));
    },
    async gerarFinanceiro(token, payload) {
        return axios.post(`${apiUrl}/api/v1/companies/${payload.id}/financeiro/`, payload, authHeaders(token));
    },
    async deleteCompany(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/companies/${userId}/`, authHeaders(token));
    },
    async deleteCompanyData(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/companies/${userId}/data/`, authHeaders(token));
    },
    async unactiveCompany(token, companyId) {
        return axios.put(`${apiUrl}/api/v1/companies/unactive/${companyId}/`, {}, authHeaders(token));
    },
    async exportCompanyData(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/companies/${companyId}/export/`, authHeaders(token));
    },
    async companyGetAllStatus(token) {
        return axios.get(`${apiUrl}/api/v1/companies/all/status/`, authHeaders(token));
    },
    async restaurarPadraoTipo(token, tipo) {
        return axios.get(`${apiUrl}/api/v1/companies/restaurar-padrao-tipo/?tipo=${tipo}`, authHeaders(token));
    },
    async getCompanies(token) {
        return axios.get(`${apiUrl}/api/v1/companies/`, authHeaders(token));
    },
    async getCompaniesFinanceiroParcelaAtraso(token) {
        return axios.get(`${apiUrl}/api/v1/companies/financeiro-parcela-atraso/`, authHeaders(token));
    },
    async getCompaniesActive(token) {
        return axios.get(`${apiUrl}/api/v1/companies/?only_active=true/`, authHeaders(token));
    },
    async getUserByCompanyId(token, payload) {
        return axios.post(`${apiUrl}/api/v1/users/get-user-by-company-id/`, payload, authHeaders(token));
    },
    async getUserByAlunoId(token, alunoId) {
        return axios.get(`${apiUrl}/api/v1/users/get-user-by-aluno-id/${alunoId}/`, authHeaders(token));
    },
    async createAlunoAcessoPortal(token, alunoId) {
        return axios.post(`${apiUrl}/api/v1/users/portal-aluno/${alunoId}/`, {}, authHeaders(token));
    },
    async desativarAlunoAcessoPortal(token, alunoId) {
        return axios.post(`${apiUrl}/api/v1/users/portal-aluno/desativar/${alunoId}/`, {}, authHeaders(token));
    },
    async ativarAlunoAcessoPortal(token, alunoId) {
        return axios.post(`${apiUrl}/api/v1/users/portal-aluno/ativar/${alunoId}/`, {}, authHeaders(token));
    },
    async getProfessores(token) {
        return axios.get(`${apiUrl}/api/v1/users/professores/`, authHeaders(token));
    },
    async getUserNames(token) {
        return axios.get(`${apiUrl}/api/v1/users/names/list/`, authHeaders(token));
    },
    async getProfessoresByIdioma(token, idioma) {
        return axios.get(`${apiUrl}/api/v1/users/professor-by-idioma/${idioma}/`, authHeaders(token));
    },
    async getProfessoresByIdiomaAndDisponibilidade(token, idioma, dias) {
        return axios.post(`${apiUrl}/api/v1/users/professor-by-idioma-and-disponibilidade/${idioma}/`, dias, authHeaders(token));
    },
    async getCompanyProfessoresByIdioma(token, idioma) {
        return axios.get(`${apiUrl}/api/v1/users/company/professor-by-idioma/${idioma}/`, authHeaders(token));
    },
    async deleteUser(token, id) {
        return axios.delete(`${apiUrl}/api/v1/users/${id}/`, authHeaders(token));
    },
    async getCdas(token) {
        return axios.get(`${apiUrl}/api/v1/users/cdas/`, authHeaders(token));
    },
    async generateNewTOTP(token) {
        return axios.post(`${apiUrl}/api/v1/otp/`, {}, authHeaders(token));
    },
    async verifyTOTPCode(token, payload) {
        return axios.get(`${apiUrl}/api/v1/otp/verify/?otp_code=${payload.code}&action_id=${payload.actionId}`, authHeaders(token));
    },
    async getLogOtp(token, userId) {
        return axios.get(`${apiUrl}/api/v1/otp/${userId}/`, authHeaders(token));
    },
    // Cargos
    async getCargos(token) {
        return axios.get(`${apiUrl}/api/v1/cargos/`, authHeaders(token));
    },
    async getCargoPermissoes(token, cargoId) {
        return axios.get(`${apiUrl}/api/v1/cargos/${cargoId}/permissoes/`, authHeaders(token));
    },
    // Departamentos
    async getDepartamentos(token) {
        return axios.get(`${apiUrl}/api/v1/departamentos/`, authHeaders(token));
    },
    // City
    async getCityByName(token, name) {
        return axios.get(`${apiUrl}/api/v1/cities/by-name/?name=${name}`, authHeaders(token));
    },
    async getCityByCodigoMunicipio(token, codigo) {
        return axios.get(`${apiUrl}/api/v1/cities/by-codigo-municipio/?codigo=${codigo}`, authHeaders(token));
    },
    async updateCompany(token, payload) {
        return axios.put(`${apiUrl}/api/v1/companies/${payload.id}/`, payload, authHeaders(token));
    },
    async updateProspex(token, payload) {
        return axios.put(`${apiUrl}/api/v1/prospexs/${payload.id}/`, payload, authHeaders(token));
    },
    async deleteProspex(token, id) {
        return axios.delete(`${apiUrl}/api/v1/prospexs/${id}/`, authHeaders(token));
    },
    async createProspex(token, data) {
        return axios.post(`${apiUrl}/api/v1/prospexs/`, data, authHeaders(token));
    },
    async getDetalhamentoTurmaAluno(token, id) {
        return axios.get(`${apiUrl}/api/v1/turma-alunos/${id}/detalhamento/`, authHeaders(token));
    },
    async getTurmaAlunoBoletins(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/turma-alunos/boletins/query/`, queryIn, authHeaders(token));
    },
    async getAniversariantesQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/alunos/aniversariantes/query/`, queryIn, authHeaders(token));
    },
    async imprimirBoasVindas(token, alunoId) {
        return axios.get(`${apiUrl}/api/v1/alunos/${alunoId}/portal/`, authHeaders(token));
    },
    async getTurmaAlunosFicha(token, id) {
        return axios.get(`${apiUrl}/api/v1/turmas/${id}/alunos/`, authHeaders(token));
    },
    async getTurmaAlunosSimplificado(token, id) {
        return axios.get(`${apiUrl}/api/v1/turmas/${id}/alunos/simplificado/`, authHeaders(token));
    },
    async getTurmaAlunos(token, payload) {
        return axios.post(`${apiUrl}/api/v1/turma-alunos/`, payload, authHeaders(token));
    },
    async deleteTurmaAluno(token, turmaAlunoId) {
        return axios.delete(`${apiUrl}/api/v1/turma-alunos/${turmaAlunoId}/`, authHeaders(token));
    },
    async deleteTurma(token, turmaId) {
        return axios.delete(`${apiUrl}/api/v1/turmas/${turmaId}/`, authHeaders(token));
    },
    async getTurmaQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/turmas/query/`, queryIn, authHeaders(token));
    },
    async cancelarTurma(token, turmaId) {
        return axios.put(`${apiUrl}/api/v1/turmas/cancelar/${turmaId}/`, {}, authHeaders(token));
    },
    async descancelarTurma(token, turmaId) {
        return axios.put(`${apiUrl}/api/v1/turmas/descancelar/${turmaId}/`, {}, authHeaders(token));
    },
    async createTurmaAluno(token, payload) {
        return axios.post(`${apiUrl}/api/v1/turma-alunos/inserir-aluno/`, payload, authHeaders(token));
    },
    async getTurmas(token, payload) {
        return axios.post(`${apiUrl}/api/v1/turmas/get-multi/`, payload, authHeaders(token));
    },
    async getTurmasCompativelTransferencia(token, turmaAlunoId) {
        return axios.get(`${apiUrl}/api/v1/turmas/compativel-transferencia/${turmaAlunoId}/`, authHeaders(token));
    },
    async imprimirFichaFrequencia(token, id) {
        return axios.get(`${apiUrl}/api/v1/turmas/imprimir/${id}/`, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    },
    async versoFichaFrequencia(token, id, exibirSomenteVigente) {
        return axios.get(`${apiUrl}/api/v1/turmas/${id}/verso/?exibir_somente_vigentes=${exibirSomenteVigente}`, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    },
    async getTurmasByProfessor(token) {
        return axios.get(`${apiUrl}/api/v1/turmas/professor/`, authHeaders(token));
    },
    async getTurmasByProfessorId(token, payload) {
        return axios.get(`${apiUrl}/api/v1/turmas/professor/${payload.professorId}/?livro_id=${payload.livro.id}`, authHeaders(token));
    },
    async getTurmasAlunosByTurma(token, payload) {
        return axios.get(`${apiUrl}/api/v1/turmas/${payload.turma_id}/turma-alunos/`, authHeaders(token));
    },
    async getTurma(token, id) {
        return axios.get(`${apiUrl}/api/v1/turmas/${id}/`, authHeaders(token));
    },
    async setEntregaLivro(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contratos/contrato-livro/${payload.contratoLivroId}/entrega/${payload.codigo}/?livro_data_entrega=${payload.livroDataEntrega}&is_estorno=${payload.isEstorno}`, {}, authHeaders(token));
    },
    async updateTurmaAtHomeIniciarAula(token, payload) {
        return axios.get(`${apiUrl}/api/v1/turmas/${payload.turmaId}/athome/iniciar-aula/${payload.meetCode}/`, authHeaders(token));
    },
    async updateTurmaLocalAula(token, turmaId, is_athome) {
        return axios.get(`${apiUrl}/api/v1/turmas/${turmaId}/athome/${is_athome}/`, authHeaders(token));
    },
    async getAllTurmasAtHome(token, curso_id, idioma) {
        let url = `${apiUrl}/api/v1/turmas/aulaonline/all/`;
        if (curso_id) {
            url += `?curso_id=${curso_id}`;
            if (idioma) {
                url += `&idioma=${idioma}`;
            }
        }
        else if (idioma) {
            url += `?idioma=${idioma}`;
        }
        return axios.get(url, authHeaders(token));
    },
    async getTurmaAlunosAtividade(token, payload) {
        return axios.get(`${apiUrl}/api/v1/turmas/${payload.turmaId}/atividade/${payload.atividade}/`, authHeaders(token));
    },
    async getTurmaAtividades(token, turmaId) {
        return axios.get(`${apiUrl}/api/v1/turma-atividades/turma/${turmaId}/`, authHeaders(token));
    },
    async getTurmaAtividadesHoje(token) {
        return axios.get(`${apiUrl}/api/v1/turma-atividades/hoje/`, authHeaders(token));
    },
    async getTurmaAtividadesByProfessor(token, professorId) {
        return axios.get(`${apiUrl}/api/v1/turma-atividades/turma/${professorId}/`, authHeaders(token));
    },
    async updateTurmaAluno(token, payload) {
        return axios.put(`${apiUrl}/api/v1/turma-alunos/${payload.turma_aluno_id}/`, payload, authHeaders(token));
    },
    async turmaAlunoEvadir(token, turmaAlunoId) {
        return axios.get(`${apiUrl}/api/v1/turma-alunos/${turmaAlunoId}/evadir/`, authHeaders(token));
    },
    async updateTurmaAtividadeHoje(token, payload) {
        return axios.put(`${apiUrl}/api/v1/turma-atividades/hoje/`, payload, authHeaders(token));
    },
    async updateTurmaAtividade(token, payload) {
        return axios.put(`${apiUrl}/api/v1/turma-atividades/${payload.id}/`, payload, authHeaders(token));
    },
    async getCurrentDateAtividadeByTurma(token, turmaId) {
        return axios.get(`${apiUrl}/api/v1/turma-atividades/turma/${turmaId}/hoje/`, authHeaders(token));
    },
    async getRelatorioFaltasQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/relatorio-faltas/query/`, queryIn, authHeaders(token));
    },
    async enviaArquivoCorrecao(token, id, file) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append('file', file, file.name);
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/${id}/enviar-arquivo-correcao/`, formData, headers);
    },
    async downloadAtividade(token, turmaAlunoAtividade, bucketArquivoAtividadeId) {
        return axios.get(`${apiUrl}/api/v1/turma-aluno-atividades/${turmaAlunoAtividade}/download-atividade/${bucketArquivoAtividadeId}/`, authHeaders(token));
    },
    async downloadCorrecao(token, turmaAlunoAtividade, bucketArquivoCorrecaoId) {
        return axios.get(`${apiUrl}/api/v1/turma-aluno-atividades/${turmaAlunoAtividade}/download-correcao/${bucketArquivoCorrecaoId}/`, authHeaders(token));
    },
    async turmaAlunoAtividadeExcluirTarefa(token, turmaAlunoAtividade, bucketArquivoCorrecaoId) {
        return axios.delete(`${apiUrl}/api/v1/turma-aluno-atividades/${turmaAlunoAtividade}/excluir-atividade/${bucketArquivoCorrecaoId}/`, authHeaders(token));
    },
    async turmaAlunoAtividadePresencaAluno(token, turmaAtividadeId) {
        return axios.put(`${apiUrl}/api/v1/turma-aluno-atividades/turma-atividade/${turmaAtividadeId}/presenca-aluno/`, {}, authHeaders(token));
    },
    async enviaArquivoAtividade(token, id, file) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append('file', file, file.name);
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/${id}/enviar-arquivo-atividade/`, formData, headers);
    },
    async enviaArquivoAtividadeEscrita(token, id, file) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append('file', file, file.name);
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/${id}/enviar-arquivo-atividade-escrita/`, formData, headers);
    },
    async turmaAlunoAtividadeNaoIraRepor(token, data) {
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/nao-repor/`, data, authHeaders(token));
    },
    async turmaAlunoAtividadeUpdate(token, payload) {
        return axios.put(`${apiUrl}/api/v1/turma-aluno-atividades/${payload.id}/`, payload, authHeaders(token));
    },
    async turmaAlunoAtividadeMarcarReposicao(token, data) {
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/marcar-reposicao/`, data, authHeaders(token));
    },
    async getTurmaAlunoAtividadeOpcoesReposicao(token, turma_aluno_atividade_id) {
        return axios.get(`${apiUrl}/api/v1/turma-aluno-atividades/${turma_aluno_atividade_id}/opcoes-reposicao/`, authHeaders(token));
    },
    async getHorariosDisponiveis(token, payload) {
        return axios.get(`${apiUrl}/api/v1/turma-aluno-atividades/horarios-disponiveis/?data=${payload.data}&idioma=${payload.idioma}`, authHeaders(token));
    },
    async createTurmaAlunosAtividades(token, content) {
        return axios.post(`${apiUrl}/api/v1/turma-aluno-atividades/`, content, authHeaders(token));
    },
    async updateHomeworkPendente(token, payload) {
        return axios.put(`${apiUrl}/api/v1/turma-aluno-atividades/homework/`, payload, authHeaders(token));
    },
    async getTurmaAlunosHomeworksPendentes(token, turmaId) {
        return axios.get(`${apiUrl}/api/v1/turma-aluno-atividades/homeworks/turma/${turmaId}/`, authHeaders(token));
    },
    async getTurmaAlunosHomeworksPendentesTurmas(token) {
        return axios.get(`${apiUrl}/api/v1/turma-aluno-atividades/homeworks/turmas/`, authHeaders(token));
    },
    async getReposicoesConcluidas(token, aluno_id, turma_id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${aluno_id}/${turma_id}/reposicoes-concluidas/`, authHeaders(token));
    },
    async ajustaTurma(token, id) {
        return axios.put(`${apiUrl}/api/v1/turmas/ajuste-turma/${id}/`, {}, authHeaders(token));
    },
    async updateTurma(token, payload) {
        return axios.put(`${apiUrl}/api/v1/turmas/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCronograma(token, payload, id) {
        return axios.put(`${apiUrl}/api/v1/turmas/cronograma/${id}/`, payload, authHeaders(token));
    },
    async getCronograma(token, id) {
        return axios.get(`${apiUrl}/api/v1/turmas/cronograma/${id}/`, authHeaders(token));
    },
    async createTurma(token, data) {
        return axios.post(`${apiUrl}/api/v1/turmas/`, data, authHeaders(token));
    },
    async getCities(token) {
        return axios.get(`${apiUrl}/api/v1/cities/`, authHeaders(token));
    },
    async getDashboardQuadroPedagogico(token, payload) {
        return axios.get(`${apiUrl}/api/v1/dashboards/quadro-pedagogico/?mes=${payload.mes}&ano=${payload.ano}`, authHeaders(token));
    },
    async getDashboardMainPage(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/main-page/`, authHeaders(token));
    },
    async getDashboardMainPageTreinamentos(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/main-page/treinamentos/`, authHeaders(token));
    },
    async getDashboardQuadroPedagogicoDetails(token, payload) {
        return axios.get(`${apiUrl}/api/v1/dashboards/quadro-pedagogico/details`, { params: payload, ...authHeaders(token) });
    },
    // Prospex
    async getProspexDashboard(token) {
        return axios.get(`${apiUrl}/api/v1/prospexs/dashboard/`, authHeaders(token));
    },
    async getProspexs(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/prospexs/query/`, queryIn, authHeaders(token));
    },
    async getProspex(token, id) {
        return axios.get(`${apiUrl}/api/v1/prospexs/${id}/`, authHeaders(token));
    },
    async getProspexAtendimentos(token) {
        return axios.get(`${apiUrl}/api/v1/prospexs/atendimentos/`, authHeaders(token));
    },
    async getExistingProspex(token, payload) {
        return axios.get(`${apiUrl}/api/v1/prospexs/?nome=${payload.name}&idade=${payload.idade}`, authHeaders(token));
    },
    async registerProspexVisit(token, payload) {
        return axios.post(`${apiUrl}/api/v1/prospexs/visita/`, payload, authHeaders(token));
    },
    async getDashboardCobranca(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/cobranca/`, authHeaders(token));
    },
    async getDashboardReposicao(token, turmaAlunoId = null) {
        if (turmaAlunoId) {
            return axios.get(`${apiUrl}/api/v1/dashboards/reposicao/?turma_aluno_id=${turmaAlunoId}`, authHeaders(token));
        }
        else {
            return axios.get(`${apiUrl}/api/v1/dashboards/reposicao/`, authHeaders(token));
        }
    },
    async getDashboardPedagogico(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/pedagogico/`, authHeaders(token));
    },
    async getDashboardCaixa(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/caixa/`, authHeaders(token));
    },
    async getDashboardEntregaLivroQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/dashboards/entrega-livro/query/`, queryIn, authHeaders(token));
    },
    async getDashboardRetencao(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/retencao/`, authHeaders(token));
    },
    async getDashboardRematricula(token) {
        return axios.get(`${apiUrl}/api/v1/dashboards/rematricula/`, authHeaders(token));
    },
    // SyngooConfiguracao
    async updateSyngooConfiguracao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/syngoo-configuracoes/${payload.id}/`, payload, authHeaders(token));
    },
    async createSyngooConfiguracao(token, data) {
        return axios.post(`${apiUrl}/api/v1/syngoo-configuracoes/`, data, authHeaders(token));
    },
    async getSyngooConfiguracoes(token) {
        return axios.get(`${apiUrl}/api/v1/syngoo-configuracoes/`, authHeaders(token));
    },
    async getSyngooConfiguracao(token) {
        return axios.get(`${apiUrl}/api/v1/syngoo-configuracoes/`, authHeaders(token));
    },
    async getSyngooConfiguracaoById(token, id) {
        return axios.get(`${apiUrl}/api/v1/syngoo-configuracoes/${id}`, authHeaders(token));
    },
    async getSyngooConfiguracaoStatus(token) {
        return axios.get(`${apiUrl}/api/v1/syngoo-configuracoes/status/`, authHeaders(token));
    },
    async deleteSyngooConfiguracao(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/syngoo-configuracoes/${userId}/`, authHeaders(token));
    },
    async getSyngooMensagemConfiguracaoListaEnvioMensagem(token, syngooMensagemId) {
        return axios.get(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/lista-envio-mensagem/${syngooMensagemId}/`, authHeaders(token));
    },
    async getSyngooMensagemConfiguracaoEnvioManualFatura(token) {
        return axios.get(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/envio-manual-fatura/`, authHeaders(token));
    },
    // SyngooMensagemConfiguracao
    async updateSyngooMensagemConfiguracao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/${payload.id}/`, payload, authHeaders(token));
    },
    async createSyngooMensagemConfiguracao(token, data) {
        return axios.post(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/`, data, authHeaders(token));
    },
    async getSyngooMensagemConfiguracoes(token) {
        return axios.get(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/`, authHeaders(token));
    },
    async getSyngooMensagemConfiguracao(token, id) {
        return axios.get(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/${id}/`, authHeaders(token));
    },
    async deleteSyngooMensagemConfiguracao(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/${userId}/`, authHeaders(token));
    },
    async SyngooEnviaWhats(token, payload) {
        return axios.post(`${apiUrl}/api/v1/syngoo-mensagem-configuracoes/enviar-whats/`, payload, authHeaders(token));
    },
    // Salas
    async updateSala(token, payload) {
        return axios.put(`${apiUrl}/api/v1/salas/${payload.id}/`, payload, authHeaders(token));
    },
    async createSala(token, data) {
        return axios.post(`${apiUrl}/api/v1/salas/`, data, authHeaders(token));
    },
    async getSalas(token) {
        return axios.get(`${apiUrl}/api/v1/salas/`, authHeaders(token));
    },
    async getSala(token, id) {
        return axios.get(`${apiUrl}/api/v1/salas/${id}/`, authHeaders(token));
    },
    async deleteSala(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/salas/${userId}/`, authHeaders(token));
    },
    // Treinamentos
    async updateTreinamento(token, payload) {
        return axios.put(`${apiUrl}/api/v1/treinamentos/${payload.id}/`, payload, authHeaders(token));
    },
    async createTreinamento(token, data) {
        return axios.post(`${apiUrl}/api/v1/treinamentos/`, data, authHeaders(token));
    },
    async getTreinamentos(token) {
        return axios.get(`${apiUrl}/api/v1/treinamentos/`, authHeaders(token));
    },
    async getTreinamento(token, id) {
        return axios.get(`${apiUrl}/api/v1/treinamentos/${id}/`, authHeaders(token));
    },
    async deleteTreinamento(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/treinamentos/${userId}/`, authHeaders(token));
    },
    // Jornada do colaborador
    async getJornadaColaborador(token, id) {
        return axios.get(`${apiUrl}/api/v1/jornadas-colaborador/${id}/`, authHeaders(token));
    },
    async createJornadaColaborador(token, data) {
        return axios.post(`${apiUrl}/api/v1/jornadas-colaborador/`, data, authHeaders(token));
    },
    // Reposiçao aluno
    async getReposicoesByAluno(token, alunoId) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/aluno/${alunoId}/`, authHeaders(token));
    },
    async getReposicoesByTurmaAtividade(token, id) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/atividade/${id}/`, authHeaders(token));
    },
    async getReposicoesAlunos(token, payload) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/alunos/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async getNewReposicoesAlunos(token) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/alunos/new/`, authHeaders(token));
    },
    async getReforcoAlunosAprovados(token) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/alunos/aprovados/`, authHeaders(token));
    },
    // Reposiçao
    async updateReposicao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/reposicoes/${payload.id}/`, payload, authHeaders(token));
    },
    async updateReposicaoAlunosPresenca(token, payload) {
        return axios.put(`${apiUrl}/api/v1/reposicoes/alunos/presenca/`, payload, authHeaders(token));
    },
    async createReposicao(token, data) {
        return axios.post(`${apiUrl}/api/v1/reposicoes/`, data, authHeaders(token));
    },
    async createReposicoesAlunos(token, data) {
        return axios.post(`${apiUrl}/api/v1/reposicoes/aluno/multi/`, data, authHeaders(token));
    },
    async changeAprovacaoReposicao(token, { value, id }) {
        return axios.post(`${apiUrl}/api/v1/reposicoes/aprovacao/${id}/?aprovacao=${value}`, value, authHeaders(token));
    },
    async getReposicoes(token) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/`, authHeaders(token));
    },
    async getReposicoesQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/reposicoes/reposicao/query/`, queryIn, authHeaders(token));
    },
    async getAulaExtraQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/reposicoes/aula-extra/query/`, queryIn, authHeaders(token));
    },
    async getReposicao(token, id) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/${id}/`, authHeaders(token));
    },
    async getReposicaoAlunos(token, id) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/${id}/aluno/`, authHeaders(token));
    },
    async getReposicaoAlunosHojeByProfessor(token, professorId) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/hoje/professor/${professorId}/alunos/`, authHeaders(token));
    },
    async getReposicaoHojeByProfessor(token, professorId) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/hoje/professor/${professorId}/`, authHeaders(token));
    },
    async getReforcoAlunosHojeByProfessor(token, professorId) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/reforco/hoje/professor/${professorId}/alunos/`, authHeaders(token));
    },
    async getReforcoHojeByProfessor(token, professorId) {
        return axios.get(`${apiUrl}/api/v1/reposicoes/reforco/hoje/professor/${professorId}/`, authHeaders(token));
    },
    async deleteReposicao(token, reposicaoId) {
        return axios.delete(`${apiUrl}/api/v1/reposicoes/${reposicaoId}/`, authHeaders(token));
    },
    // Campanhas
    async updateCampanha(token, payload) {
        return axios.put(`${apiUrl}/api/v1/campanhas/${payload.id}/`, payload, authHeaders(token));
    },
    async createCampanha(token, data) {
        return axios.post(`${apiUrl}/api/v1/campanhas/`, data, authHeaders(token));
    },
    async sincronizarCampanha(token, campanhaId) {
        return axios.get(`${apiUrl}/api/v1/campanhas/${campanhaId}/sincronizar/`, authHeaders(token));
    },
    async getCampanhas(token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/`, authHeaders(token));
    },
    async getCampanha(token, id) {
        return axios.get(`${apiUrl}/api/v1/campanhas/${id}/`, authHeaders(token));
    },
    async pauseCampanha(token, userId, facebook_token) {
        return axios.delete(`${apiUrl}/api/v1/campanhas/activate/${userId}/?facebook_token=${facebook_token}`, authHeaders(token));
    },
    async startCampanha(token, userId, facebook_token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/activate/${userId}/?facebook_token=${facebook_token}`, authHeaders(token));
    },
    async getCampanhasInsights(token, code) {
        return axios.get(`${apiUrl}/api/v1/campanhas/insights/?code=${code}`, authHeaders(token));
    },
    async getCampanhaInsights(token, code, id) {
        return axios.get(`${apiUrl}/api/v1/campanhas/insights/${id}/?code=${code}`, authHeaders(token));
    },
    async getTokens(token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/tokens/`, authHeaders(token));
    },
    async UpdateFacebookToken(token, facebook_token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/me/facebook/?facebook_token=${facebook_token}`, authHeaders(token));
    },
    async SincronizarCampanhasFacebook(token, facebook_token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/sincronizar/?facebook_token=${facebook_token}`, authHeaders(token));
    },
    async SincronizarImports(token, campanhas) {
        return axios.post(`${apiUrl}/api/v1/campanhas/sincronizar/submit/`, campanhas, authHeaders(token));
    },
    async getFacebookBalance(token, facebook_token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/facebook/balance/${facebook_token}/`, authHeaders(token));
    },
    async GetGoogleURL(token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/google/code/`, authHeaders(token));
    },
    async googleToken(token, code) {
        return axios.get(`${apiUrl}/api/v1/campanhas/google/token/?code=${code}`, authHeaders(token));
    },
    async createGoogleCampanha(token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/google/campanha/`, authHeaders(token));
    },
    async getGoogleCampanhas(token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/google/campanha/read/`, authHeaders(token));
    },
    async updateCampanhasConfig(token, data, id) {
        return axios.put(`${apiUrl}/api/v1/campanhas/config/${id}/`, data, authHeaders(token));
    },
    async getFacebookPages(token, facebookToken) {
        return axios.get(`${apiUrl}/api/v1/campanhas/facebook/pages/${facebookToken}/`, authHeaders(token));
    },
    async getCampanhaAccounts(token) {
        return axios.get(`${apiUrl}/api/v1/campanhas/accounts/`, authHeaders(token));
    },
    // Documentos Fiscais
    async updateDocumentoFiscal(token, payload) {
        return axios.put(`${apiUrl}/api/v1/documentos-fiscais/${payload.id}/`, payload, authHeaders(token));
    },
    async createDocumentoFiscal(token, data) {
        return axios.post(`${apiUrl}/api/v1/documentos-fiscais/`, data, authHeaders(token));
    },
    async getDocumentosFiscais(token) {
        return axios.get(`${apiUrl}/api/v1/documentos-fiscais/`, authHeaders(token));
    },
    async getDocumentoFiscal(token, id) {
        return axios.get(`${apiUrl}/api/v1/documentos-fiscais/${id}/`, authHeaders(token));
    },
    async deleteDocumentoFiscal(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/documentos-fiscais/${userId}/`, authHeaders(token));
    },
    // Veículos
    async updateVeiculo(token, payload) {
        return axios.put(`${apiUrl}/api/v1/veiculos/${payload.id}/`, payload, authHeaders(token));
    },
    async createVeiculo(token, data) {
        return axios.post(`${apiUrl}/api/v1/veiculos/`, data, authHeaders(token));
    },
    async getVeiculos(token) {
        return axios.get(`${apiUrl}/api/v1/veiculos/`, authHeaders(token));
    },
    async getVeiculo(token, id) {
        return axios.get(`${apiUrl}/api/v1/veiculos/${id}/`, authHeaders(token));
    },
    async deleteVeiculo(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/veiculos/${userId}/`, authHeaders(token));
    },
    // Ponto Parada
    async updatePontoParada(token, payload) {
        return axios.put(`${apiUrl}/api/v1/pontos-parada/${payload.id}/`, payload, authHeaders(token));
    },
    async createPontoParada(token, data) {
        return axios.post(`${apiUrl}/api/v1/pontos-parada/`, data, authHeaders(token));
    },
    async getPontosParada(token) {
        return axios.get(`${apiUrl}/api/v1/pontos-parada/`, authHeaders(token));
    },
    async getPontoParada(token, id) {
        return axios.get(`${apiUrl}/api/v1/pontos-parada/${id}/`, authHeaders(token));
    },
    async deletePontoParada(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/pontos-parada/${userId}/`, authHeaders(token));
    },
    // Rota
    async updateRota(token, payload) {
        return axios.put(`${apiUrl}/api/v1/rotas/${payload.rota.id}/`, payload, authHeaders(token));
    },
    async createRota(token, data) {
        return axios.post(`${apiUrl}/api/v1/rotas/`, data, authHeaders(token));
    },
    async getRotas(token) {
        return axios.get(`${apiUrl}/api/v1/rotas/`, authHeaders(token));
    },
    async getRota(token, id) {
        return axios.get(`${apiUrl}/api/v1/rotas/${id}/`, authHeaders(token));
    },
    async getRotaPDF(token, id) {
        return axios.get(`${apiUrl}/api/v1/rotas/pdf/${id}/`, authHeaders(token));
    },
    async deleteRota(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/rotas/${userId}/`, authHeaders(token));
    },
    async getAlunoSemTransporte(token) {
        return axios.get(`${apiUrl}/api/v1/rotas/aluno-sem-transporte/`, authHeaders(token));
    },
    async getAlunoRetirarTransporte(token) {
        return axios.get(`${apiUrl}/api/v1/rotas/aluno-retirar-transporte/`, authHeaders(token));
    },
    async getAlunoChecarOrdemFinanceiro(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/checar-ordem/`, authHeaders(token));
    },
    async alunoArrumaOrdemFinanceiro(token, id) {
        return axios.put(`${apiUrl}/api/v1/alunos/${id}/arruma-ordem/`, {}, authHeaders(token));
    },
    // Rota Pontos Parada
    async updateRotaPontoParada(token, payload) {
        return axios.put(`${apiUrl}/api/v1/rota-pontos-parada/${payload.id}/`, payload, authHeaders(token));
    },
    async createRotaPontoParada(token, data) {
        return axios.post(`${apiUrl}/api/v1/rota-pontos-parada/`, data, authHeaders(token));
    },
    async getRotaPontosParada(token, rota_id) {
        return axios.get(`${apiUrl}/api/v1/rota-pontos-parada/${rota_id}/`, authHeaders(token));
    },
    async deleteRotaPontoParada(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/rota-pontos-parada/${userId}/`, authHeaders(token));
    },
    async getRotaAlunos(token, rotaPontoParadaId) {
        return axios.get(`${apiUrl}/api/v1/rotas/${rotaPontoParadaId}/alunos/`, authHeaders(token));
    },
    // Rota Ponto Parada Alunos
    async updateRotaPontoParadaAluno(token, payload) {
        return axios.put(`${apiUrl}/api/v1/rota-ponto-parada-alunos/`, payload, authHeaders(token));
    },
    async createRotaPontoParadaAluno(token, data) {
        return axios.post(`${apiUrl}/api/v1/rota-ponto-parada-alunos/`, data, authHeaders(token));
    },
    async getRotaPontoParadaAlunos(token) {
        return axios.get(`${apiUrl}/api/v1/rota-ponto-parada-alunos/`, authHeaders(token));
    },
    async getRotaPontoParadaAluno(token, id) {
        return axios.get(`${apiUrl}/api/v1/rota-ponto-parada-alunos/${id}/`, authHeaders(token));
    },
    async deleteRotaPontoParadaAluno(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/rota-ponto-parada-alunos/${userId}/`, authHeaders(token));
    },
    // Alunos
    async updateAluno(token, payload) {
        return axios.put(`${apiUrl}/api/v1/alunos/${payload.id}/`, payload, authHeaders(token));
    },
    async createAluno(token, data) {
        return axios.post(`${apiUrl}/api/v1/alunos/`, data, authHeaders(token));
    },
    async deleteAluno(token, alunoId) {
        return axios.delete(`${apiUrl}/api/v1/alunos/${alunoId}/`, authHeaders(token));
    },
    async exluirAlunoContratoFinanceiro(token, company_id) {
        return axios.delete(`${apiUrl}/api/v1/alunos/company/${company_id}/`, authHeaders(token));
    },
    async createOcorrencia(token, payload) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (payload.files.length > 0) {
            for (const file of payload.files) {
                formData.append('files', file.file, file.file.name);
            }
        }
        formData.append('ocorrencia_in', JSON.stringify(payload.data));
        return axios.post(`${apiUrl}/api/v1/ocorrencias/`, formData, headers);
    },
    async getAnalyticsAlunoPorContrato(token) {
        return axios.get(`${apiUrl}/api/v1/analytics/aluno-por-contrato/`, authHeaders(token));
    },
    async getAnalyticsContrato(token) {
        return axios.get(`${apiUrl}/api/v1/analytics/contrato/`, authHeaders(token));
    },
    async getAnalyticsCadernoDesistencia(token, payload) {
        return axios.get(`${apiUrl}/api/v1/analytics/caderno-desistencia/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async getAnalyticsCadernoEvadidos(token, payload) {
        return axios.get(`${apiUrl}/api/v1/analytics/caderno-evadidos/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async getAnalyticsCadernoAgendado(token, payload) {
        return axios.get(`${apiUrl}/api/v1/analytics/caderno-agendado/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async getAnalyticsAniversariantes(token, payload) {
        return axios.get(`${apiUrl}/api/v1/analytics/aniversariantes/?mes_id=${payload.mes_id}`, authHeaders(token));
    },
    async getAnalyticsParceriaCupons(token, parceriaId = null, cdaId = null) {
        let params = '&';
        if (parceriaId != null) {
            params = `?parceria_id=${parceriaId}`;
        }
        if (cdaId != null) {
            params = `?cda_id=${cdaId}`;
        }
        if (params == '&') {
            params = '';
        }
        return axios.get(`${apiUrl}/api/v1/analytics/parceria-cupom/${params}`, authHeaders(token));
    },
    async getAnalyticsProspex(token, payload) {
        return axios.get(`${apiUrl}/api/v1/analytics/prospex/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async getAnalyticsTurmas(token, params) {
        return axios.post(`${apiUrl}/api/v1/analytics/turma/`, params, authHeaders(token));
    },
    async getAnalyticsCadernoExterno(token, payload) {
        return axios.get(`${apiUrl}/api/v1/analytics/caderno-externo/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async getAnalyticsOcorrencia(token, params) {
        return axios.post(`${apiUrl}/api/v1/analytics/ocorrencias/?data_inicial=${params.data_inicial}&data_final=${params.data_final}&entidade_id=${params.entidade_id}&entidade_tipo=${params.entidade_tipo}`, params, authHeaders(token));
    },
    async getAnalyticsFinanceiroParcela(token, params) {
        return axios.post(`${apiUrl}/api/v1/analytics/financeiro-parcela/`, params, authHeaders(token));
    },
    async getAnalyticsFinanceiroParcelaMovimentacao(token, params) {
        return axios.post(`${apiUrl}/api/v1/analytics/financeiro-parcela-movimentacao/`, params, authHeaders(token));
    },
    async getAnalyticsEntregaLivro(token) {
        return axios.get(`${apiUrl}/api/v1/analytics/entrega-livro/`, authHeaders(token));
    },
    async getAnalyticsSyngooHistoricoEnvio(token, params) {
        return axios.post(`${apiUrl}/api/v1/analytics/syngoo-historico-envio/?data_inicial=${params.data_inicial}&data_final=${params.data_final}`, params, authHeaders(token));
    },
    async getAnalyticsSyngooEnvioDeMensagem(token) {
        return axios.get(`${apiUrl}/api/v1/analytics/syngoo-envio-mensagem/`, authHeaders(token));
    },
    async getAnalyticsRelatorioFaltas(token, params) {
        return axios.post(`${apiUrl}/api/v1/analytics/relatorio-faltas/?data_inicial=${params.data_inicial}&data_final=${params.data_final}`, params, authHeaders(token));
    },
    async getAnalyticsReposicao(token, payload) {
        return axios.post(`${apiUrl}/api/v1/analytics/reposicao/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, payload, authHeaders(token));
    },
    async getAnalyticsAulaExtra(token, payload) {
        return axios.post(`${apiUrl}/api/v1/analytics/aula-extra/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, payload, authHeaders(token));
    },
    async getAlunos(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/alunos/query/`, queryIn, authHeaders(token));
    },
    async getAlunoContratoPDF(token, contratoId) {
        return axios.get(`${apiUrl}/api/v1/alunos/contrato/${contratoId}/`, authHeaders(token));
    },
    async getServicoCarne(token, payload) {
        return axios.get(`${apiUrl}/api/v1/alunos/carne/?contrato_livro_id=${payload.contratoLivroId}&tipo=${payload.tipo}&aluno_id=${payload.alunoId}`, authHeaders(token));
    },
    async getAluno(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/`, authHeaders(token));
    },
    async getAlunoByCPF(token, cpf) {
        return axios.get(`${apiUrl}/api/v1/alunos/cpf/${cpf}/`, authHeaders(token));
    },
    async getPedagogico(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/pedagogico/`, authHeaders(token));
    },
    async getPedagogicoTurmas(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/pedagogico-turmas/`, authHeaders(token));
    },
    async getBoletim(token, id) {
        return axios.get(`${apiUrl}/api/v1/turma-alunos/${id}/boletim/`, authHeaders(token));
    },
    async getAlunoFinanceiro(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/financeiro/`, authHeaders(token));
    },
    async getAlunoTransporte(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/transporte/`, authHeaders(token));
    },
    async getAlunoOcorrencias(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/ocorrencias/`, authHeaders(token));
    },
    async getAlunoIndicacoes(token, id) {
        return axios.get(`${apiUrl}/api/v1/alunos/${id}/indicacoes/`, authHeaders(token));
    },
    async getOcorrenciasBy(token, payload) {
        return axios.get(`${apiUrl}/api/v1/ocorrencias/?dia=${payload.dia}&user_id=${payload.user_id}`, authHeaders(token));
    },
    async getOcorrencias(token, payload) {
        return axios.get(`${apiUrl}/api/v1/ocorrencias/?entidade_id=${payload.entidade_id}&entidade_tipo=${payload.entidade_tipo}`, authHeaders(token));
    },
    async getMultiOcorrencias(token, payload) {
        return axios.post(`${apiUrl}/api/v1/ocorrencias/multi/`, payload, authHeaders(token));
    },
    async getOcorrenciasQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/ocorrencias/query/`, queryIn, authHeaders(token));
    },
    async getOcorrenciasById(token, id) {
        return axios.get(`${apiUrl}/api/v1/ocorrencias/id/${id}/`, authHeaders(token));
    },
    async getOcorrenciaAnexos(token, payload) {
        return axios.get(`${apiUrl}/api/v1/ocorrencias/anexos/?ocorrencia_id=${payload.id}&entidade_tipo=${payload.entidade_tipo}`, authHeaders(token));
    },
    async getOcorrenciaAnexo(token, payload) {
        return axios.get(`${apiUrl}/api/v1/ocorrencias/anexo/?ocorrencia_id=${payload.id}&entidade_tipo=${payload.entidade_tipo}&blob_full_name=${payload.full_name}`, authHeaders(token));
    },
    async deleteOcorrenciaAnexo(token, payload) {
        return axios.delete(`${apiUrl}/api/v1/ocorrencias/anexo/?entidade_tipo=${payload.entidade_tipo}&blob_full_name=${payload.full_name}`, authHeaders(token));
    },
    async deleteOcorrencia(token, id) {
        return axios.delete(`${apiUrl}/api/v1/ocorrencias/${id}/`, authHeaders(token));
    },
    async updateOcorrencia(token, payload) {
        return axios.put(`${apiUrl}/api/v1/ocorrencias/${payload.id}/`, payload, authHeaders(token));
    },
    async updateOcorrenciaTarefa(token, payload) {
        return axios.put(`${apiUrl}/api/v1/ocorrencia-tarefas/${payload.id}/`, payload, authHeaders(token));
    },
    async migrarOcorrenciaTarefa(token, payload) {
        return axios.put(`${apiUrl}/api/v1/ocorrencia-tarefas/migrar/${payload.id}/`, payload.data, authHeaders(token));
    },
    async getOcorrenciaTarefasByUserId(token, id) {
        return axios.get(`${apiUrl}/api/v1/ocorrencia-tarefas/user/${id}/`, authHeaders(token));
    },
    async getEndereco(cep) {
        return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    },
    async getCursos(token) {
        return axios.get(`${apiUrl}/api/v1/cursos/`, authHeaders(token));
    },
    async getCursosEtapas(token) {
        return axios.get(`${apiUrl}/api/v1/cursos/etapas/`, authHeaders(token));
    },
    async getCaixas(token, payload) {
        return axios.get(`${apiUrl}/api/v1/caixas/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}&tipo=${payload.tipo}`, authHeaders(token));
    },
    async updateCaixa(token, payload) {
        return axios.put(`${apiUrl}/api/v1/caixas/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCaixaMovimentacaoDetalhamento(token, payload) {
        return axios.put(`${apiUrl}/api/v1/caixas/movimentacao/detalhamento/${payload.id}/`, payload, authHeaders(token));
    },
    async getCaixaMovimentacao(token, caixaId) {
        return axios.get(`${apiUrl}/api/v1/caixas/${caixaId}/movimentacao/`, authHeaders(token));
    },
    async getCaixa(token, caixaId) {
        return axios.get(`${apiUrl}/api/v1/caixas/${caixaId}/`, authHeaders(token));
    },
    async imprimirCaixa(token, payload) {
        return axios.post(`${apiUrl}/api/v1/caixas/imprimir/`, payload, authHeaders(token));
    },
    async abrirCaixa(token, payload) {
        return axios.post(`${apiUrl}/api/v1/caixas/`, payload, authHeaders(token));
    },
    async caixaEfetuarLancamento(token, payload) {
        return axios.post(`${apiUrl}/api/v1/caixas/lancamento/`, payload, authHeaders(token));
    },
    async getAtividades(token, livro_id) {
        return axios.get(`${apiUrl}/api/v1/atividades/${livro_id}/`, authHeaders(token));
    },
    // Livro
    // async getLivro(token: string, payload) {
    //   const params = new URLSearchParams();
    //   // @ts-ignore
    //   params.append(Object.keys(payload)[0], Object.values(payload)[0]);
    //   const request = authHeaders(token);
    //   // @ts-ignore
    //   request.params = params;
    // },
    async getLivros(token) {
        return axios.get(`${apiUrl}/api/v1/livros/`, authHeaders(token));
    },
    async getLivrosEGuides(token) {
        return axios.get(`${apiUrl}/api/v1/livros/livros-guides/`, authHeaders(token));
    },
    async getListeningLivros(token, id) {
        return axios.get(`${apiUrl}/api/v1/livros-aluno/${id}/`, authHeaders(token));
    },
    async getListening(token, codigo) {
        return axios.get(`${apiUrl}/api/v1/livros-aluno/listening/${codigo}/`, authHeaders(token));
    },
    async getGlossary(token, codigo) {
        return axios.get(`${apiUrl}/api/v1/livros-aluno/glossary/${codigo}/`, authHeaders(token));
    },
    async createListening(token, payload) {
        return axios.post(`${apiUrl}/api/v1/livros-aluno/`, payload, authHeaders(token));
    },
    // Feriados
    async getFeriados(token) {
        return axios.get(`${apiUrl}/api/v1/feriados/`, authHeaders(token));
    },
    async getFeriadosPeriodo(token, datas) {
        return axios.get(`${apiUrl}/api/v1/feriados/${datas.data_inicio}/${datas.data_fim}/`, authHeaders(token));
    },
    async atualizaFeriados(token, ano) {
        return axios.put(`${apiUrl}/api/v1/feriados/atualiza-feriados/${ano}/`, {}, authHeaders(token));
    },
    async gerarAquivoFeriadoAno(token, ano) {
        return axios.put(`${apiUrl}/api/v1/feriados/gerar-arquivo/${ano}/`, {}, authHeaders(token));
    },
    async updateFeriado(token, id, feriadoIn) {
        return axios.put(`${apiUrl}/api/v1/feriados/${id}/`, feriadoIn, authHeaders(token));
    },
    async createFeriado(token, feriadoIn) {
        return axios.post(`${apiUrl}/api/v1/feriados/`, feriadoIn, authHeaders(token));
    },
    async deleteFeriado(token, id) {
        return axios.delete(`${apiUrl}/api/v1/feriados/${id}/`, authHeaders(token));
    },
    async getCompanyConfiguracoes(token, companyId = null) {
        if (companyId) {
            return axios.get(`${apiUrl}/api/v1/companies/configuracoes/?company_id=${companyId}`, authHeaders(token));
        }
        else {
            return axios.get(`${apiUrl}/api/v1/companies/configuracoes/`, authHeaders(token));
        }
    },
    async updateCompanyConfiguracoesContrato(token, contrato) {
        return axios.put(`${apiUrl}/api/v1/companies/configuracoes/contrato/`, { contrato }, authHeaders(token));
    },
    async updateCompanyConfiguracoesPermiteVisualizarCompartilharTumasAtHome(token, permiteVisualizarCompartilharTurmasAtHome) {
        return axios.put(`${apiUrl}/api/v1/companies/configuracoes/compartilhar-turmas-at-home/`, { permite_visualizar_compartilhar_turmas_at_home: permiteVisualizarCompartilharTurmasAtHome }, authHeaders(token));
    },
    async sincronizarSponte(token, payload) {
        return axios.post(`${apiUrl}/api/v1/companies/sincronizar-sponte/`, payload, authHeaders(token));
    },
    async sincronizarMatricom(token) {
        return axios.post(`${apiUrl}/api/v1/companies/sincronizar-matricom/`, {}, authHeaders(token));
    },
    async updateCompanyConfiguracoes(token, payload) {
        return axios.put(`${apiUrl}/api/v1/companies/configuracoes/`, payload, authHeaders(token));
    },
    // Curso Desconto
    async updateCompanyConfiguracaoCursoDesconto(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-curso-descontos/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCompanyConfiguracaoCursoDescontos(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-curso-descontos/`, payload, authHeaders(token));
    },
    async createCompanyConfiguracaoCursoDesconto(token, data) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-curso-descontos/`, data, authHeaders(token));
    },
    async getCompanyConfiguracaoCursoDescontos(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-curso-descontos/`, authHeaders(token));
    },
    async getCompanyConfiguracaoCursoDesconto(token, id) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-curso-descontos/${id}/`, authHeaders(token));
    },
    async deleteCompanyConfiguracaoCursoDesconto(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/company-configuracao-curso-descontos/${userId}/`, authHeaders(token));
    },
    // Integracao Bancaria
    async getCompanyConfiguracaoIntegracaoBancariaAtiva(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/obtem-ativa/`, authHeaders(token));
    },
    async getCompanyConfiguracaoIntegracaoBancariaAtivaAdm(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/obtem-ativa/admin/`, authHeaders(token));
    },
    async syncCompanyConfiguracaoIntegracaoBancariaCertificados(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/sync/certificados/`, authHeaders(token));
    },
    async updateCompanyConfiguracaoIntegracaoBancariaCertificados(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/renovar/certificados/`, authHeaders(token));
    },
    async updateCompanyConfiguracaoIntegracaoBancaria(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCompanyConfiguracaoIntegracaoBancarias(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/`, payload, authHeaders(token));
    },
    async createCompanyConfiguracaoIntegracaoBancaria(token, data) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/`, data, authHeaders(token));
    },
    async getCompanyConfiguracaoIntegracaoBancaria(token, id) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/${id}/`, authHeaders(token));
    },
    async getCompanyConfiguracaoIntegracaoBancarias(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/company/${companyId}/`, authHeaders(token));
    },
    async getCompanyConfiguracaoIntegracaoBancariasByContaBancariaId(token, contaBancariaId) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/conta-bancaria/${contaBancariaId}/`, authHeaders(token));
    },
    async deleteCompanyConfiguracaoIntegracaoBancaria(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/${userId}/`, authHeaders(token));
    },
    async gerarCertificadoCompanyConfiguracaoIntegracaoBancaria(token, id) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/gerar-certificado/${id}/`, authHeaders(token));
    },
    async cadastrarWebhookCompanyConfiguracaoIntegracaoBancaria(token, companyConfiguracaoIntegracaoBancariaId) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/webhook-cadastrar/${companyConfiguracaoIntegracaoBancariaId}/`, {}, authHeaders(token));
    },
    async excluirWebhookCompanyConfiguracaoIntegracaoBancaria(token, companyConfiguracaoIntegracaoBancariaId) {
        return axios.delete(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/webhook-excluir/${companyConfiguracaoIntegracaoBancariaId}/`, authHeaders(token));
    },
    async consultarWebhookCompanyConfiguracaoIntegracaoBancaria(token, companyConfiguracaoIntegracaoBancariaId) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/webhook-consultar/${companyConfiguracaoIntegracaoBancariaId}/`, authHeaders(token));
    },
    async tokenCompanyConfiguracaoIntegracaoBancaria(token, companyConfiguracaoIntegracaoBancariaId) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-integracao-bancaria/token/${companyConfiguracaoIntegracaoBancariaId}`, authHeaders(token));
    },
    // Dia vencimento parcelas
    async updateCompanyConfiguracaoDiaVencimentoParcela(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-dia-vencimento-parcelas/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCompanyConfiguracaoDiaVencimentoParcelas(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-dia-vencimento-parcelas/`, { payload }, authHeaders(token));
    },
    async createCompanyConfiguracaoDiaVencimentoParcela(token, data) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-dia-vencimento-parcelas/`, data, authHeaders(token));
    },
    async getCompanyConfiguracaoDiaVencimentoParcelas(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-dia-vencimento-parcelas/`, authHeaders(token));
    },
    async getCompanyConfiguracaoDiaVencimentoParcela(token, id) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-dia-vencimento-parcelas/${id}/`, authHeaders(token));
    },
    async deleteCompanyConfiguracaoDiaVencimentoParcela(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/company-configuracao-dia-vencimento-parcelas/${userId}/`, authHeaders(token));
    },
    // Negociacao
    async updateCompanyConfiguracaoNegociacao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-negociacao/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCompanyConfiguracaoNegociacoes(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-negociacoes/`, payload, authHeaders(token));
    },
    async createCompanyConfiguracaoNegociacao(token, data) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-negociacao/`, data, authHeaders(token));
    },
    async getCompanyConfiguracaoNegociacoes(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-negociacoes/`, authHeaders(token));
    },
    async getCompanyConfiguracaoNegociacao(token, id) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-negociacao/${id}/`, authHeaders(token));
    },
    async deleteCompanyConfiguracaoNegociacao(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/company-configuracao-negociacao/${userId}/`, authHeaders(token));
    },
    // Negociacao da rescisao do contrato
    async updateCompanyConfiguracaoNegociacaoMultaRescisaoContrato(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-negociacao-multa-rescisoria-contrato/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCompanyConfiguracaoNegociacoesMultaRescisaoContrato(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-negociacoes-multa-rescisoria-contrato/`, payload, authHeaders(token));
    },
    async createCompanyConfiguracaoNegociacaoMultaRescisaoContrato(token, data) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-negociacao-multa-rescisoria-contrato/`, data, authHeaders(token));
    },
    async getCompanyConfiguracaoNegociacoesMultaRescisaoContrato(token) {
        return axios.get(`
      ${apiUrl}/api/v1/company-configuracao-negociacoes-multa-rescisoria-contrato/`, authHeaders(token));
    },
    async getCompanyConfiguracaoNegociacaoMultaRescisaoContrato(token, id) {
        return axios.get(`
      ${apiUrl}/api/v1/company-configuracao-negociacao-multa-rescisoria-contrato/${id}/`, authHeaders(token));
    },
    async deleteCompanyConfiguracaoNegociacaoMultaRescisaoContrato(token, userId) {
        return axios.delete(`
      ${apiUrl}/api/v1/company-configuracao-negociacao-multa-rescisoria-contrato/${userId}/`, authHeaders(token));
    },
    // Parcelamento material
    async updateCompanyConfiguracaoMaterialParcelamento(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-material-parcelamentos/${payload.id}/`, payload, authHeaders(token));
    },
    async updateCompanyConfiguracaoMaterialParcelamentos(token, payload) {
        return axios.put(`${apiUrl}/api/v1/company-configuracao-material-parcelamentos/`, payload, authHeaders(token));
    },
    async createCompanyConfiguracaoMaterialParcelamento(token, data) {
        return axios.post(`${apiUrl}/api/v1/company-configuracao-material-parcelamentos/`, data, authHeaders(token));
    },
    async getCompanyConfiguracaoMaterialParcelamentos(token) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-material-parcelamentos/`, authHeaders(token));
    },
    async getCompanyConfiguracaoMaterialParcelamento(token, id) {
        return axios.get(`${apiUrl}/api/v1/company-configuracao-material-parcelamentos/${id}/`, authHeaders(token));
    },
    async deleteCompanyConfiguracaoMaterialParcelamento(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/company-configuracao-material-parcelamentos/${userId}/`, authHeaders(token));
    },
    async getContratos(token, payload) {
        const tipo = typeof payload;
        if (tipo == 'string' || tipo == 'number') {
            return axios.get(`${apiUrl}/api/v1/contratos/aluno/${payload}/`, authHeaders(token));
        }
        else {
            return axios.post(`${apiUrl}/api/v1/contratos/buscar/`, payload, authHeaders(token));
        }
    },
    async getCadernoMatricula(token, payload) {
        return axios.post(`${apiUrl}/api/v1/contratos/caderno-matricula/`, payload, authHeaders(token));
    },
    async getContratosAlunosRematricular(token, dataInicial, dataFinal) {
        return axios.post(`${apiUrl}/api/v1/contratos/buscar/alunos-rematricular/?data_inicial=${dataInicial}&data_final=${dataFinal}`, {}, authHeaders(token));
    },
    async getContratoQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/contratos/query/`, queryIn, authHeaders(token));
    },
    async getContratoMovimentacao(token, contratoId) {
        return axios.get(`${apiUrl}/api/v1/contratos/${contratoId}/movimentacao/`, authHeaders(token));
    },
    async getContratoLivroMovimentacao(token, contratoLivroId) {
        return axios.get(`${apiUrl}/api/v1/contratos/estagio/${contratoLivroId}/movimentacao/`, authHeaders(token));
    },
    async getContrato(token, contratoId) {
        return axios.get(`${apiUrl}/api/v1/contratos/${contratoId}/`, authHeaders(token));
    },
    async trancarContrato(token, payload) {
        return axios.post(`${apiUrl}/api/v1/contratos/trancar/`, payload, authHeaders(token));
    },
    async getMasterPreInvoice(token, preInvoiceId, contratoLivroId) {
        if (contratoLivroId) {
            return axios.get(`${apiUrl}/api/v1/contratos/master-pre-invoice/${preInvoiceId}/contrato_livro/${contratoLivroId}/`, authHeaders(token));
        }
        return axios.get(`${apiUrl}/api/v1/contratos/master-pre-invoice/${preInvoiceId}/`, authHeaders(token));
    },
    async gerarFinanceiroLivro(token, payload) {
        const url = `${apiUrl}/api/v1/contratos/gerar-parcelas/`;
        return axios.post(url, payload, authHeaders(token));
    },
    async desvincularLivroMaster(token, preInvoiceId) {
        const url = `${apiUrl}/api/v1/contratos/pre-invoice/${preInvoiceId}/desvincular-livro/`;
        return axios.put(url, {}, authHeaders(token));
    },
    async contratoAlterarTransporte(token, contratoTransporte) {
        const url = `${apiUrl}/api/v1/contratos/${contratoTransporte.id}/alterar-transporte/`;
        return axios.put(url, contratoTransporte, authHeaders(token));
    },
    async contratoRematriculou(token, contratoId) {
        return axios.put(`${apiUrl}/api/v1/contratos/${contratoId}/status_rematriculavel/`, {}, authHeaders(token));
    },
    async contratoNaoRematriculou(token, contratoId) {
        return axios.put(`${apiUrl}/api/v1/contratos/${contratoId}/status_nao_rematriculavel/`, {}, authHeaders(token));
    },
    async contratoFinalizou(token, contratoId) {
        return axios.put(`${apiUrl}/api/v1/contratos/${contratoId}/status_finalizou/`, {}, authHeaders(token));
    },
    async getCadernoDesistenciaQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/contratos/caderno-desistencia/query/`, queryIn, authHeaders(token));
    },
    async getCadernoEvadidosQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/turma-alunos/caderno-evadidos/query/`, queryIn, authHeaders(token));
    },
    async getCadernoExternoQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/parceria-acoes/caderno-externo/query/`, queryIn, authHeaders(token));
    },
    async getContratoDadosCancelamento(token, contrato_id) {
        return axios.get(`${apiUrl}/api/v1/contratos/${contrato_id}/dados-rescisao/`, authHeaders(token));
    },
    async getAlunoTaxaMatricula(token, contrato_id) {
        return axios.get(`${apiUrl}/api/v1/contratos/${contrato_id}/taxa-matricula/`, authHeaders(token));
    },
    async imprimirReciboEntregaMaterial(token, contrato_id) {
        return axios.get(`${apiUrl}/api/v1/contratos/${contrato_id}/entrega-livro-comprovante/`, authHeaders(token));
    },
    async restaurarContratoPadrao(token) {
        return axios.get(`${apiUrl}/api/v1/contratos/restaurar-contrato-padrao/`, authHeaders(token));
    },
    async updateContrato(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contratos/${payload.id}/`, payload, authHeaders(token));
    },
    async rescindirContrato(token, payload) {
        return axios.post(`${apiUrl}/api/v1/contratos/${payload.id}/rescindir/`, payload, authHeaders(token));
    },
    async imprimirTermoDesistencia(token, contratoId) {
        // return axios.get(`${apiUrl}/api/v1/contratos/termo/${contratoId}/`, authHeaders(token));
        return axios.get(`${apiUrl}/api/v1/contratos/termo-desistencia/${contratoId}/`, authHeaders(token));
    },
    async imprimirTermoTrancamento(token, contratoId) {
        return axios.get(`${apiUrl}/api/v1/contratos/termo-trancamento/${contratoId}/`, authHeaders(token));
    },
    async deleteContrato(token, contratoId) {
        return axios.delete(`${apiUrl}/api/v1/contratos/${contratoId}/`, authHeaders(token));
    },
    async deleteContratoLivro(token, contrato_livro_id) {
        return axios.delete(`${apiUrl}/api/v1/contratos/estagio/${contrato_livro_id}/`, authHeaders(token));
    },
    async updateContratoVigenciaInicio(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contratos/${payload.id}/vigencia-inicio/`, payload, authHeaders(token));
    },
    async alterarEstagio(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contratos/${payload.contrato_livro_id}/alterar-estagio/`, payload, authHeaders(token));
    },
    async adicionarEstagio(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contratos/adicionar-estagio/`, payload, authHeaders(token));
    },
    async solicitarLivroParaFranchiseEstagioLivro(token, payload) {
        return axios.post(`${apiUrl}/api/v1/contratos/solicitar-livro-franchise/`, payload, authHeaders(token));
    },
    async solicitarLivroParaFranchiseVerificaPossibilidade(token, contratoLivroId) {
        return axios.get(`${apiUrl}/api/v1/contratos/solicitar-livro-franchise/${contratoLivroId}/verifica-possibilidade`, authHeaders(token));
    },
    async getAlunosTableColunas(token) {
        const headers = authHeaders(token);
        headers.responseType = 'blob';
        return axios.get(`${apiUrl}/api/v1/alunos/template/`, headers);
    },
    async previewFileAlunosData(token, formData) {
        return axios.post(`${apiUrl}/api/v1/alunos/preview-import-data/`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async importAlunosTemplateData(token, alunos_in) {
        return axios.post(`${apiUrl}/api/v1/alunos/import-data/`, alunos_in, authHeaders(token));
    },
    async createContrato(token, payload) {
        // Ajustar
        return axios.post(`${apiUrl}/api/v1/contratos/`, payload, authHeaders(token));
    },
    async updateParceria(token, payload) {
        return axios.put(`${apiUrl}/api/v1/parcerias/${payload.id}/`, payload, authHeaders(token));
    },
    async createParceria(token, data) {
        return axios.post(`${apiUrl}/api/v1/parcerias/`, data, authHeaders(token));
    },
    async getParcerias(token) {
        return axios.get(`${apiUrl}/api/v1/parcerias/`, authHeaders(token));
    },
    async getParceriaRestaurarPadrao(token, id) {
        return axios.get(`${apiUrl}/api/v1/parcerias/${id}/restaurar-padrao/`, authHeaders(token));
    },
    async getParceriasCampanha(token) {
        return axios.get(`${apiUrl}/api/v1/parcerias/campanha/`, authHeaders(token));
    },
    async getParceria(token, id) {
        return axios.get(`${apiUrl}/api/v1/parcerias/${id}/`, authHeaders(token));
    },
    async getParceriaDivulgacao(token, id) {
        return axios.get(`${apiUrl}/api/v1/parcerias/divulgacao/${id}/`, authHeaders(token));
    },
    async getParceriaFlyer(token, id) {
        return axios.get(`${apiUrl}/api/v1/parcerias/${id}/flyer`, authHeaders(token));
    },
    async getParceriaCartaz(token, id) {
        return axios.get(`${apiUrl}/api/v1/parcerias/${id}/cartaz`, authHeaders(token));
    },
    async deleteParceria(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/parcerias/${userId}/`, authHeaders(token));
    },
    // PARCERIA ACOES
    async getParceriaAcoes(token, parceria_id) {
        return axios.get(`${apiUrl}/api/v1/parceria-acoes/parceria/${parceria_id}/`, authHeaders(token));
    },
    async deleteParceriaAcao(token, parceriaAcaoId) {
        return axios.delete(`${apiUrl}/api/v1/parceria-acoes/${parceriaAcaoId}/`, authHeaders(token));
    },
    async updateParceriaAcao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/parceria-acoes/`, payload, authHeaders(token));
    },
    async updateStatusParceriaAcao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/parceria-acoes/${payload.id}/status/${payload.status_id}/`, {}, authHeaders(token));
    },
    async getParceriaAcoesPlanejamentoComercial(token, payload) {
        return axios.get(`${apiUrl}/api/v1/parceria-acoes/planejamento-comercial/${payload.data_inicio}/${payload.data_fim}/`, authHeaders(token));
    },
    // PARCERIA CUPONS
    async updateParceriaCupom(token, payload) {
        return axios.put(`${apiUrl}/api/v1/parceria-cupons/${payload.id}/`, payload, authHeaders(token));
    },
    async getParceriaCuponsQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons/query/`, queryIn, authHeaders(token));
    },
    async createParceriaCupom(token, data) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons/`, data, authHeaders(token));
    },
    async createParceriaCupomN8N(token, data) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons/n8n/`, data, authHeaders(token));
    },
    async createParceriaCupomFromLandingPage(token, data) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons/landingpage-cupom/`, data, authHeaders(token));
    },
    async getParceriaCupons(token, payload) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/parceria/${payload.parceriaId}/?status_pendente_ligacao=${payload.statusPendenteLigacao}`, authHeaders(token));
    },
    async getParceriaCupomByTelefone(token, telefone) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/by-telefone/${telefone}/`, authHeaders(token));
    },
    async getParceriaCuponsAgendados(token, data) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/cda/dashboard/${data}/`, authHeaders(token));
    },
    // desativado
    // async landingPageVerifyTelefoneParceriaCupom(telefone: string) {
    //   return axios.get(`${apiUrl}/api/v1/parceria-cupons/landingpage-cupom/telefone/${telefone}`);
    // },
    async getParceriaCupom(token, id) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/${id}/`, authHeaders(token));
    },
    async getParceriaCupomByParceriaId(token, id) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/${id}/`, authHeaders(token));
    },
    async getParceriaCupomByCda(token, id) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/cda/${id}/`, authHeaders(token));
    },
    async desvincularParceriaCupomCda(token, id) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/${id}/desvincular-cda/`, authHeaders(token));
    },
    async deleteParceriaCupom(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/parceria-cupons/${userId}/`, authHeaders(token));
    },
    async getCupons(token) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/me/`, authHeaders(token));
    },
    async getCuponsById(token, id) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/me/${id}/`, authHeaders(token));
    },
    async getQuadroCda(token, periodo) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/quadro/${periodo[0]}/${periodo[1]}/`, authHeaders(token));
    },
    async getCadernoCda(token, payload) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons/caderno/cda/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    async distribuirCupons(token, data) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons/distribuir/`, data, authHeaders(token));
    },
    async excluirCuponsLote(token, data) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons/excluir-lote/`, data, authHeaders(token));
    },
    async migrarCupons(token, payload) {
        return axios.put(`${apiUrl}/api/v1/parceria-cupons/migrar/${payload.id}/`, payload.data, authHeaders(token));
    },
    // Parceria Cupom Historico
    async getParceriaCupomHistorico(token, payload) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons-historico/`, authHeaders(token));
    },
    async getParceriaCupomHistoricoByParceriaCupomId(token, parceriaId) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons-historico/parceria-cupom/${parceriaId}/`, authHeaders(token));
    },
    async getParceriaCupomHistoricoPeriodo(token, data) {
        return axios.get(`${apiUrl}/api/v1/parceria-cupons-historico/periodo/${data.inicio}/${data.fim}/`, authHeaders(token));
    },
    async createParceriaCupomHistorico(token, payload) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons-historico/`, payload, authHeaders(token));
    },
    async getParceriaCupomHistoricoQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/parceria-cupons-historico/query/`, queryIn, authHeaders(token));
    },
    // Financeiro
    async updateFinanceiro(token, payload) {
        return axios.put(`${apiUrl}/api/v1/financeiros/${payload.id}/`, payload, authHeaders(token));
    },
    async createFinanceiro(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiros/`, data, authHeaders(token));
    },
    async createFinanceiroComplete(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiros/complete/`, payload, authHeaders(token));
    },
    async getFinanceiroContasPagar(token) {
        return axios.get(`${apiUrl}/api/v1/financeiros/pagar/`, authHeaders(token));
    },
    async getFinanceiroContasReceber(token) {
        return axios.get(`${apiUrl}/api/v1/financeiros/receber/`, authHeaders(token));
    },
    async getFinanceiro(token, id) {
        return axios.get(`${apiUrl}/api/v1/financeiros/${id}/`, authHeaders(token));
    },
    async getFinanceirosMaterialDidatico(token, dataInicial, dataFinal, tipoDocumento, tipoPesquisa) {
        return axios.get(`${apiUrl}/api/v1/financeiros/material-didatico/?data_inicial=${dataInicial}&data_final=${dataFinal}&tipo_documento=${tipoDocumento}&tipo_pesquisa=${tipoPesquisa}`, authHeaders(token));
    },
    async deleteFinanceiro(token, financeiroId) {
        return axios.delete(`${apiUrl}/api/v1/financeiros/${financeiroId}/`, authHeaders(token));
    },
    // Financeiro Parcela
    async getFinanceiroParcelas(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/buscar/`, payload, authHeaders(token));
    },
    async getFinanceiroParcelasAnexos(token, id) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/anexos/${id}/`, authHeaders(token));
    },
    async getFinanceiroParcelasQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/query/`, queryIn, authHeaders(token));
    },
    async getFinanceiroParcelasDrive(token) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/drive/`, authHeaders(token));
    },
    async getFinanceirosParcelas(token, ano) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/reajuste-material/?ano=${ano}`, authHeaders(token));
    },
    async getFinanceiroParcela(token, id) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/${id}/`, authHeaders(token));
    },
    async deleteConta(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/delete/conta/`, payload, authHeaders(token));
    },
    async imprimirRecibo(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/recibo/`, payload, authHeaders(token));
    },
    async updateFinanceiroParcela(token, payload) {
        return axios.put(`${apiUrl}/api/v1/financeiro-parcelas/${payload.id}/`, payload, authHeaders(token));
    },
    async quitarParcelaContaAPagar(token, payload, anexos) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (anexos) {
            if (anexos.length > 0) {
                for (const file of payload.anexos) {
                    formData.append('anexo', file.file, file.file.name);
                }
            }
        }
        formData.append('quitar_in', JSON.stringify(payload));
        return axios.put(`${apiUrl}/api/v1/financeiro-parcelas/quitar/conta-a-pagar/`, formData, headers);
    },
    async getUrlBucketArquivo(token, bucketArquivoId) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/conta-a-pagar/anexo/${bucketArquivoId}/`, authHeaders(token));
    },
    async updateFinanceiroVencimentoEmLote(token, payload) {
        return axios.put(`${apiUrl}/api/v1/financeiro-parcelas/`, payload, authHeaders(token));
    },
    async cancelarFinanceiroParcelas(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/cancelar/`, data, authHeaders(token));
    },
    async baixaManualFinanceiroParcela(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/baixa-manual/`, data, authHeaders(token));
    },
    async protestarFinanceiroParcelas(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/protestar/`, data, authHeaders(token));
    },
    async retirarProtestoFinanceiroParcelas(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/retirar-protesto/`, data, authHeaders(token));
    },
    async renegociarFinanceiroParcelas(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/renegociar/`, data, authHeaders(token));
    },
    async createFinanceiroParcelas(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/multi/`, data, authHeaders(token));
    },
    async deleteFinanceiroParcela(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/financeiro-parcelas/${userId}/`, authHeaders(token));
    },
    async getFinanceiroParcelasByFinanceiro(token, financeiro_id) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/by-financeiro/${financeiro_id}/`, authHeaders(token));
    },
    async getFinanceiroParcelaComprovante(token, id) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcelas/comprovante/${id}/`, authHeaders(token));
    },
    async plugnotasNfseEmitirFinanceiroParcelaMovimentacao(token, financeiroParcelaMovimentacaoId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/emitir/financeiro-parcela-movimentacao/${financeiroParcelaMovimentacaoId}/`, authHeaders(token));
    },
    async plugnotasNfseEmitirFinanceiroParcela(token, financeiroParcelaId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/emitir/financeiro_parcela/${financeiroParcelaId}/`, authHeaders(token));
    },
    async updatePlugnotasNfseServico(token, payload) {
        return axios.put(`${apiUrl}/api/v1/plugnotas-nfse-servico/${payload.id}/`, payload, authHeaders(token));
    },
    async createPlugnotasNfseServico(token, plugNotasNfseServicoCreate) {
        return axios.post(`${apiUrl}/api/v1/plugnotas-nfse-servico/`, plugNotasNfseServicoCreate, authHeaders(token));
    },
    async deletePlugnotasNfseServico(token, id) {
        return axios.delete(`${apiUrl}/api/v1/plugnotas-nfse-servico/${id}/`, authHeaders(token));
    },
    async getPlugnotasNfseServico(token, id) {
        return axios.get(`${apiUrl}/api/v1/plugnotas-nfse-servico/${id}/`, authHeaders(token));
    },
    async getPlugnotasNfseServicosByCompanyId(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas-nfse-servico/company/${companyId}/`, authHeaders(token));
    },
    async plugnotasNfceBuscarFinanceiro(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas-nfce-produto/financeiro-categoria/`, authHeaders(token));
    },
    async plugnotasNfceEmitirFinanceiro(token, financeiroId) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfce/emitir/financeiro/${financeiroId}/`, {}, authHeaders(token));
    },
    async plugnotasNfceEmitirFinanceiroParcelaMovimentacao(token, financeiroParcelaMovimentacaoId) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfce/emitir/financeiro-parcela-movimentacao/${financeiroParcelaMovimentacaoId}/`, {}, authHeaders(token));
    },
    async plugnotasNfceEmitirFinanceiroParcela(token, financeiroParcelaId) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfce/emitir/financeiro-parcela/${financeiroParcelaId}/`, {}, authHeaders(token));
    },
    async plugnotasSolicitarCancelamento(token, tipoNota, plugnotasId) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/${plugnotasId}/solicitar-cancelamento/?tipo_nota=${tipoNota}`, {}, authHeaders(token));
    },
    async plugnotasNfeEmitirTroca(token, payload) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfe/emitir/troca/`, payload, authHeaders(token));
    },
    async plugnotasNfceProdutoDelete(token, id) {
        return axios.delete(`${apiUrl}/api/v1/plugnotas-nfce-produto/${id}/`, authHeaders(token));
    },
    async plugnotasNfceProdutoCreate(token, obj) {
        return axios.post(`${apiUrl}/api/v1/plugnotas-nfce-produto/`, obj, authHeaders(token));
    },
    async plugnotasNfceProdutoUpdate(token, obj) {
        return axios.put(`${apiUrl}/api/v1/plugnotas-nfce-produto/${obj.id}/`, obj, authHeaders(token));
    },
    async plugnotasNfseConsultar(token, financeiroParcelaMovimentacaoId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/consultar/financeiro-parcela-movimentacao/${financeiroParcelaMovimentacaoId}/`, authHeaders(token));
    },
    async plugnotasNfseConsultarFinanceiroParcela(token, financeiroParcelaId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/consultar/financeiro-parcela/${financeiroParcelaId}/`, authHeaders(token));
    },
    async plugnotasNfceConsultarFinanceiro(token, financeiroId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfce/consultar/financeiro/${financeiroId}/`, authHeaders(token));
    },
    async plugnotasNfeEmitirFinanceiro(token, financeiroId) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfe/emitir/financeiro/${financeiroId}/`, {}, authHeaders(token));
    },
    async plugnotasNfeReemitirTroca(token, nfe_id) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfe/${nfe_id}/emitir/troca/`, {}, authHeaders(token));
    },
    async plugnotasNfeConsultarFinanceiro(token, financeiroId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfe/consultar/financeiro/${financeiroId}/`, authHeaders(token));
    },
    async plugnotasNfeConsultarTrocaDevolucao(token, nfeId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfe/${nfeId}/consultar/troca-devolucao/`, authHeaders(token));
    },
    async plugnotasNfceConsultarFinanceiroParcela(token, financeiroParcelaId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfce/consultar/financeiro-parcela/${financeiroParcelaId}/`, authHeaders(token));
    },
    async plugnotasNfceProdutosByCompanyId(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas-nfce-produto/company/${companyId}/`, authHeaders(token));
    },
    async plugnotasRelatorioPorCNPJ(token, payload) {
        const params = `?tipo_nota=${payload.tipo_nota}&company_id=${payload.company_id}&data_inicio=${payload.data_inicio}&data_fim=${payload.data_fim}`;
        return axios.get(`${apiUrl}/api/v1/plugnotas/relatorio/${params}`, authHeaders(token));
    },
    async plugnotasNfsePdfDownload(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/${plugnotasId}/pdf/`, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${plugnotasId}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    },
    async plugnotasNfePdfDownload(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfe/${plugnotasId}/pdf/`, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
        });
    },
    async plugnotasNfcePdfDownload(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfce/${plugnotasId}/pdf/`, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${plugnotasId}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    },
    async plugnotasNfseXmlDownload(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/${plugnotasId}/xml/`, authHeaders(token));
    },
    async plugnotasNfceXmlDownload(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfce/${plugnotasId}/xml/`, authHeaders(token));
    },
    async plugnotasNfeXmlDownload(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfe/${plugnotasId}/xml/`, authHeaders(token));
    },
    async gerandoArquivoXmlPeriodo(token, tipoNota, dataInicial, dataFinal) {
        if (tipoNota === 'nfse') {
            return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/xml/periodo/?data_inicial=${dataInicial}&data_final=${dataFinal}`, authHeaders(token));
        }
        else if (tipoNota === 'nfce') {
            return axios.get(`${apiUrl}/api/v1/plugnotas/nfce/xml/periodo/?data_inicial=${dataInicial}&data_final=${dataFinal}`, authHeaders(token));
        }
        else if (tipoNota === 'nfe') {
            return axios.get(`${apiUrl}/api/v1/plugnotas/nfe/xml/periodo/?data_inicial=${dataInicial}&data_final=${dataFinal}`, authHeaders(token));
        }
    },
    async enviarCartaCorrecao(token, plugnotasId, textoParaCorrecao) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfe/${plugnotasId}/carta-correcao/`, { texto_correcao: textoParaCorrecao }, authHeaders(token));
    },
    async plugnotasNfceXmlsDownload(token, plugnotasIds) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/nfce/xmls/`, plugnotasIds, authHeaders(token));
    },
    async plugnotasNfseCancelar(token, plugnotasId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfse/${plugnotasId}/cancelar/`, authHeaders(token));
    },
    async plugnotasEmpresaConsulta(token, companyId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/empresa/${companyId}/`, authHeaders(token));
    },
    async atualizarDadosCadastroAPiPlugNotas(token, plugnotas) {
        return axios.put(`${apiUrl}/api/v1/plugnotas/${plugnotas.cpfCnpj}/`, plugnotas, authHeaders(token));
    },
    async plugnotasCnpjConsulta(token, cnpj) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/empresa/cnpj/${cnpj}/`, authHeaders(token));
    },
    async plugnotasCreditoAdquirir(token, payload) {
        return axios.post(`${apiUrl}/api/v1/plugnotas/credito-adquirir/`, payload, authHeaders(token));
    },
    async plugnotasCreditoHistoricoAquisicao(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/credito-historico-aquisicao/`, authHeaders(token));
    },
    async plugnotasBuscaCertificado(token, certificadoId, companyId) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/certificado/${certificadoId}/company/${companyId}/`, authHeaders(token));
    },
    async plugnotasResumoEmissaoNotas(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/resumo-emissao-notas/`, authHeaders(token));
    },
    async plugnotasVerificaIntegracao(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/verifica-integracao/`, authHeaders(token));
    },
    async getNfeTrocaDevolucao(token, dataInicial, dataFinal) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/nfe/consultar/troca-devolucao/?data_inicial=${dataInicial}&data_final=${dataFinal}`, authHeaders(token));
    },
    async getPlugnotasProdutosStatus(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas/produtos-status/`, authHeaders(token));
    },
    async getPlugnotasConfiguracaoMe(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas-configuracoes/me/`, authHeaders(token));
    },
    async createPlugnotasConfiguracao(token, payload) {
        return axios.post(`${apiUrl}/api/v1/plugnotas-configuracoes/`, payload, authHeaders(token));
    },
    async updatePlugnotasConfiguracao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/plugnotas-configuracoes/`, payload, authHeaders(token));
    },
    async plugNotasConfiguracaoInserirCertificado(token, plugNotasConfiguracaoCertificado) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append('arquivo', plugNotasConfiguracaoCertificado.arquivo, plugNotasConfiguracaoCertificado.arquivo.name);
        formData.append('senha', plugNotasConfiguracaoCertificado.senha);
        formData.append('email', plugNotasConfiguracaoCertificado.email);
        formData.append('company_id', plugNotasConfiguracaoCertificado.company_id.toString());
        return axios.post(`${apiUrl}/api/v1/plugnotas/certificado/`, formData, headers);
    },
    async plugNotasConfiguracaoAtualizaCertificado(token, certificadoId, plugNotasConfiguracaoCertificado) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        formData.append('arquivo', plugNotasConfiguracaoCertificado.arquivo, plugNotasConfiguracaoCertificado.arquivo.name);
        formData.append('senha', plugNotasConfiguracaoCertificado.senha);
        formData.append('email', plugNotasConfiguracaoCertificado.email);
        formData.append('company_id', plugNotasConfiguracaoCertificado.company_id.toString());
        return axios.put(`${apiUrl}/api/v1/plugnotas/certificado/${certificadoId}/`, formData, headers);
    },
    async plugNotasConfiguracaoAceite(token, payload) {
        return axios.post(`${apiUrl}/api/v1/plugnotas-configuracoes/aceite/`, {
            regime_tributario: payload.regimeTributario,
            simples_nacional: payload.simplesNacional
        }, authHeaders(token));
    },
    // BRY
    async bryColeta(token, chave) {
        return axios.get(`${apiUrl}/api/v1/bry/coletas/${chave}/`, authHeaders(token));
    },
    async bryColetas(token) {
        return axios.get(`${apiUrl}/api/v1/bry/coletas/`, authHeaders(token));
    },
    async bryColetaParticipantes(token, chave) {
        return axios.get(`${apiUrl}/api/v1/bry/coletas/${chave}/participantes/`, authHeaders(token));
    },
    async imprimirTitulos(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcelas/imprimir/${payload.aluno_id}/`, payload.payload, authHeaders(token));
    },
    async pixGerarCertificadoCsr(token, payload) {
        return axios.post(`${apiUrl}/api/v1/pix/certificado-csr/`, payload, authHeaders(token));
    },
    async pixListarRecebimentos(token, payload) {
        return axios.post(`${apiUrl}/api/v1/pix/listar/`, payload, authHeaders(token));
    },
    async pixCobrancaConsultar(token, payload) {
        return axios.post(`${apiUrl}/api/v1/pix/cobranca/consultar/`, payload, authHeaders(token));
    },
    async pixConsultar(token, payload) {
        return axios.post(`${apiUrl}/api/v1/pix/consultar/`, payload, authHeaders(token));
    },
    async pixCobrancaCriar(token, payload) {
        return axios.post(`${apiUrl}/api/v1/pix/cobranca-criar/`, payload, authHeaders(token));
    },
    async pixCobrancaConsulta(token, txid) {
        return axios.get(`${apiUrl}/api/v1/pix/cobranca/${txid}/`, authHeaders(token));
    },
    async pixConsulta(token, e2eid) {
        return axios.get(`${apiUrl}/api/v1/pix/${e2eid}/`, authHeaders(token));
    },
    async pixSincronizarRecebimentos(token, payload) {
        return axios.post(`${apiUrl}/api/v1/pix/sincronizar-recebimentos/`, payload, authHeaders(token));
    },
    // financeiro parcela renegociacao
    async getFinanceiroParcelaRengociacao(token, financeiro_parcela_id) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcela-renegociacoes/${financeiro_parcela_id}/`, authHeaders(token));
    },
    // Mapa de horarios
    async getMapaHorarioPorSala(token, payload) {
        return axios.get(`${apiUrl}/api/v1/mapa-horarios/sala-dia/?month=${payload.month}&year=${payload.year}&day=${payload.day}`, authHeaders(token));
    },
    async getMapaHorarioTarefasReunioes(token, payload) {
        return axios.get(`${apiUrl}/api/v1/mapa-horarios/tarefa-reuniao/?start_month=${payload.startMonth}&start_year=${payload.startYear}&start_day=${payload.startDay}&end_month=${payload.endMonth}&end_year=${payload.endYear}&end_day=${payload.endDay}`, authHeaders(token));
    },
    async getHorarioPorSemana(token, payload) {
        return axios.get(`${apiUrl}/api/v1/mapa-horarios/professor-semana/?start_month=${payload.startMonth}&start_year=${payload.startYear}&start_day=${payload.startDay}&end_month=${payload.endMonth}&end_year=${payload.endYear}&end_day=${payload.endDay}`, authHeaders(token));
    },
    async getCronogramaPorSemana(token, payload) {
        return axios.get(`${apiUrl}/api/v1/mapa-horarios/cronograma-semana/?start_month=${payload.startMonth}&start_year=${payload.startYear}&start_day=${payload.startDay}&end_month=${payload.endMonth}&end_year=${payload.endYear}&end_day=${payload.endDay}`, authHeaders(token));
    },
    // Financeiro Parcela Movimentacao
    async estornarFinanceiroParcelaMovimentacao(token, id) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/${id}/estornar/`, {}, authHeaders(token));
    },
    async updateFinanceiroParcelaMovimentacao(token, payload) {
        return axios.put(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/${payload.id}/`, payload, authHeaders(token));
    },
    async createFinanceiroParcelaMovimentacao(token, data) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/`, data, authHeaders(token));
    },
    async createFinanceiroParcelaMovimentacaoTitulos(token, payload) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (payload.files.length > 0) {
            for (const file of payload.files) {
                formData.append('files', file.file, file.file.name);
            }
        }
        formData.append('titulos_movimentacoes_in', JSON.stringify(payload.titulosMovimentacoes));
        formData.append('titulos_in', JSON.stringify(payload.titulos));
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/titulos/?abater_diferenca=${payload.abaterDiferenca}&area_abater=${payload.areaDeAbatimento ? payload.areaDeAbatimento.toLowerCase() : null}&marcar_como_quitado=${payload.marcarComoQuitado}`, formData, headers);
    },
    async createFinanceiroParcelaMovimentacaoAnexos(token, payload) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (payload.files.length > 0) {
            for (const file of payload.files) {
                formData.append('files', file.file, file.file.name);
            }
        }
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/anexos/?caixa_movimentacao_id=${payload.caixa_movimentacao_id}`, formData, headers);
    },
    async deleteFinanceiroParcelaMovimentacaoAnexos(token, file_name) {
        return axios.delete(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/anexos/?file_name=${file_name}`, authHeaders(token));
    },
    async getFinanceiroParcelaMovimentacaoAnexos(token, payload) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/anexos/?financeiro_parcela_movimentacao_id=${payload.id}`, authHeaders(token));
    },
    async getFinanceiroParcelaMovimentacaoAnexo(token, payload) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/anexo/?financeiro_parcela_movimentacao_id=${payload.id}&blob_full_name=${payload.full_name}`, authHeaders(token));
    },
    async getFinanceiroParcelaMovimentacao(token, id) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/${id}/`, authHeaders(token));
    },
    async getFinanceiroParcelaMovimentacoesByParcelaId(token, parcelaId) {
        return axios.get(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/parcela/${parcelaId}/`, authHeaders(token));
    },
    async getFinanceiroParcelaMovimentacoes(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/buscar/`, payload, authHeaders(token));
    },
    async deleteFinanceiroParcelaMovimentacao(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/${userId}/`, authHeaders(token));
    },
    async validateCodigoTerminal(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/codigo/`, payload, authHeaders(token));
    },
    async getFinanceiroParcelaMovimentacaoQuery(token, queryIn) {
        return axios.post(`${apiUrl}/api/v1/financeiro-parcela-movimentacoes/query/`, queryIn, authHeaders(token));
    },
    // Fornecedor
    async updateFornecedor(token, payload) {
        return axios.put(`${apiUrl}/api/v1/fornecedores/${payload.id}/`, payload, authHeaders(token));
    },
    async createFornecedor(token, data) {
        return axios.post(`${apiUrl}/api/v1/fornecedores/`, data, authHeaders(token));
    },
    async getFornecedores(token) {
        return axios.get(`${apiUrl}/api/v1/fornecedores/`, authHeaders(token));
    },
    async getFornecedor(token, id) {
        return axios.get(`${apiUrl}/api/v1/fornecedores/${id}/`, authHeaders(token));
    },
    async getFornecedorByCnpj(token, cnpj) {
        return axios.get(`${apiUrl}/api/v1/fornecedores/cnpj/${cnpj}/`, authHeaders(token));
    },
    async deleteFornecedor(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/fornecedores/${userId}/`, authHeaders(token));
    },
    // Conta bancária
    async updateContaBancaria(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contas-bancarias/${payload.id}/`, payload, authHeaders(token));
    },
    async createContaBancaria(token, data) {
        return axios.post(`${apiUrl}/api/v1/contas-bancarias/`, data, authHeaders(token));
    },
    async getContasBancarias(token, is_active = null) {
        let params = '';
        if (is_active != null) {
            params = `?is_active=${is_active}`;
        }
        return axios.get(`${apiUrl}/api/v1/contas-bancarias/${params}`, authHeaders(token));
    },
    async getContaBancaria(token, id) {
        return axios.get(`${apiUrl}/api/v1/contas-bancarias/${id}/`, authHeaders(token));
    },
    async deleteContaBancaria(token, userId) {
        return axios.delete(`${apiUrl}/api/v1/contas-bancarias/${userId}/`, authHeaders(token));
    },
    async createContaBancariaMovimentacoes(token, payload) {
        return axios.post(`${apiUrl}/api/v1/contas-bancarias/movimentacoes/`, payload.movimentacoes, authHeaders(token));
    },
    async createContaBancariaMovimentacoesConferenciaDeCaixa(token, payload) {
        return axios.post(`${apiUrl}/api/v1/contas-bancarias/movimentacoes/conferencia-caixa/${payload.id}/`, payload.items, authHeaders(token));
    },
    // nao vai ser usado o query por enquanto
    // async getContaBancariaMovimentacoesQuery(token: string, queryIn: QueryCreate) {
    //   return axios.post<QueryReturn>(
    //     `${apiUrl}/api/v1/contas-bancarias/movimentacoes/query`,
    //     queryIn,
    //     authHeaders(token)
    //   );
    // },
    async getContaBancariaMovimentacoes(token, id) {
        return axios.get(`${apiUrl}/api/v1/contas-bancarias/${id}/movimentacoes/`, authHeaders(token));
    },
    async getContaBancariaMovimentacoesByCaixaId(token, id) {
        return axios.get(`${apiUrl}/api/v1/contas-bancarias/caixa/${id}/movimentacoes/`, authHeaders(token));
    },
    // Conta Bancária Forma de Pagamento
    async updateContaBancariaFormaPagamento(token, payload) {
        return axios.put(`${apiUrl}/api/v1/contas-bancarias-forma-pagamento/${payload.contaBancariaId}/`, payload.data, authHeaders(token));
    },
    async createContaBancariaFormaPagamento(token, data) {
        return axios.post(`${apiUrl}/api/v1/contas-bancarias-forma-pagamento/`, data, authHeaders(token));
    },
    async getContasBancariasFormasPagamentos(token) {
        return axios.get(`${apiUrl}/api/v1/contas-bancarias-forma-pagamento/`, authHeaders(token));
    },
    async getContaBancariaFormaPagamento(token, id) {
        return axios.get(`${apiUrl}/api/v1/contas-bancarias-forma-pagamento/${id}/`, authHeaders(token));
    },
    async getFormasPagamentosDisponiveis(token) {
        return axios.get(`${apiUrl}/api/v1/contas-bancarias-forma-pagamento/disponiveis/`, authHeaders(token));
    },
    async getFinanceiroCategorias(token) {
        return axios.get(`${apiUrl}/api/v1/financeiro-categorias/`, authHeaders(token));
    },
    async getFinanceiroCategoriasTreeview(token) {
        return axios.get(`${apiUrl}/api/v1/financeiro-categorias/treeview/`, authHeaders(token));
    },
    async createFinanceiroCategoria(token, payload) {
        return axios.post(`${apiUrl}/api/v1/financeiro-categorias/`, payload, authHeaders(token));
    },
    async updateFinanceiroCategoria(payload, token) {
        return axios.put(`${apiUrl}/api/v1/financeiro-categorias/${payload.id_fc}/`, payload, authHeaders(token));
    },
    async deleteFinanceiroCategoria(id, token) {
        return axios.delete(`${apiUrl}/api/v1/financeiro-categorias/${id}/`, authHeaders(token));
    },
    async getFinanceiroCategoriasJurosDesconto(token) {
        return axios.get(`${apiUrl}/api/v1/financeiro-categorias-juros-desconto/`, authHeaders(token));
    },
    async updateFinanceiroCategoriasJurosDesconto(payload, token) {
        return axios.post(`${apiUrl}/api/v1/financeiro-categorias-juros-desconto/update/`, payload, authHeaders(token));
    },
    // Diagnosticos
    async getDiagnostico(token) {
        return axios.get(`${apiUrl}/api/v1/diagnosticos/`, authHeaders(token));
    },
    async getDiagnosticoPedagogico(token) {
        return axios.get(`${apiUrl}/api/v1/diagnosticos/pedagogico/`, authHeaders(token));
    },
    async getDiagnosticoPedagogicoAcoes(token) {
        return axios.get(`${apiUrl}/api/v1/diagnosticos/pedagogico/acoes/`, authHeaders(token));
    },
    async getDiagnosticoPedagogicoAcoesIndex(token, index) {
        return axios.post(`${apiUrl}/api/v1/diagnosticos/pedagogico/acoes/${index.params.index.value}/`, index, authHeaders(token));
    },
    async getDiagnosticoFinanceiro(token) {
        return axios.get(`${apiUrl}/api/v1/diagnosticos/financeiro/`, authHeaders(token));
    },
    async getDiagnosticoComercial(token, payload) {
        return axios.get(`${apiUrl}/api/v1/diagnosticos/comercial/?data_inicial=${payload.data_inicial}&data_final=${payload.data_final}`, authHeaders(token));
    },
    // SEFAZ
    async getDadosCadastro(token) {
        return axios.get(`${apiUrl}/api/v1/sefaz/dados-cadastro/`, authHeaders(token));
    },
    async getStatusServicos(token) {
        return axios.get(`${apiUrl}/api/v1/sefaz/status-servicos/`, authHeaders(token));
    },
    async getConsultaDistribuicao(token) {
        return axios.get(`${apiUrl}/api/v1/sefaz/consulta-distribuicao/`, authHeaders(token));
    },
    // Banco
    async getBancos(token) {
        return axios.get(`${apiUrl}/api/v1/bancos/`, authHeaders(token));
    },
    // Entidade
    async getEntidadesByField(token, payload) {
        const params = new URLSearchParams(payload.types.map((s) => ['type', s]));
        params.append('text', payload.value);
        if (payload.departamento_id) {
            params.append('departamento_id', payload.departamento_id);
        }
        else {
            params.append('departamento_id', '0');
        }
        params.append('show_inactive', payload.show_inactive);
        params.append('radio_label', payload.radio_label);
        params.append('radio_field', payload.radio_field);
        const request = authHeaders(token);
        // @ts-ignore
        request.params = params;
        return axios.get(`${apiUrl}/api/v1/entidades/`, request);
    },
    async getEntidadesList(token, payload) {
        const params = new URLSearchParams(payload.types.map((s) => ['type', s]));
        if (payload.departamento_id) {
            params.append('departamento_id', payload.departamento_id);
        }
        else {
            params.append('departamento_id', '0');
        }
        params.append('show_inactive', payload.show_inactive);
        const request = authHeaders(token);
        // @ts-ignore
        request.params = params;
        return axios.get(`${apiUrl}/api/v1/entidades/list/`, request);
    },
    // Objecaos
    async getObjecaos(token) {
        return axios.get(`${apiUrl}/api/v1/objecoes/`, authHeaders(token));
    },
    async getLog(token, model, modelId) {
        return axios.get(`${apiUrl}/api/v1/logs/model/${model}/${modelId}/`, authHeaders(token));
    },
    async getLogById(token, logId, model, model_id) {
        return axios.get(`${apiUrl}/api/v1/logs/${logId}/model/${model}/${model_id}/`, authHeaders(token));
    },
    async getLogUserTool(token, customParams) {
        return axios.get(`${apiUrl}/api/v1/logs/user/tools/${customParams.user_id}/${customParams.tool_id}/`, authHeaders(token));
    },
    async getLogByIdUserTool(token, id, customParams) {
        return axios.get(`${apiUrl}/api/v1/logs/user/tools/${customParams.user_id}/${customParams.tool_id}/${id}/`, authHeaders(token));
    },
    async getLogUserAction(token, customParams) {
        return axios.get(`${apiUrl}/api/v1/logs/user/action/${customParams.user_id}/${customParams.action_id}/`, authHeaders(token));
    },
    async getLogByIdUserAction(token, id, customParams) {
        return axios.get(`${apiUrl}/api/v1/logs/user/action/${customParams.user_id}/${customParams.action_id}/${id}/`, authHeaders(token));
    },
    async getTurmaAlunoAtividadeLog(token, payload) {
        return axios.get(`${apiUrl}/api/v1/logs/turma/atividades/${payload.turma_id}/${payload.atividade_id}/${payload.aluno_id}/`, authHeaders(token));
    },
    async getFullTurmaAlunosAtividadeLog(token, payload) {
        return axios.get(`${apiUrl}/api/v1/logs/turma/atividades/${payload.turma_id}/`, authHeaders(token));
    },
    async getEstoque(token) {
        return axios.get(`${apiUrl}/api/v1/estoques/`, authHeaders(token));
    },
    async getEstoqueHistorico(token, id) {
        return axios.get(`${apiUrl}/api/v1/estoques/historico/${id}/`, authHeaders(token));
    },
    async createEstoqueXml(token, payload) {
        return axios.post(`${apiUrl}/api/v1/estoques/xml/`, payload, authHeaders(token));
    },
    async createEstoque(token, payload) {
        return axios.post(`${apiUrl}/api/v1/estoques/`, payload, authHeaders(token));
    },
    async createAjusteEstoque(token, payload) {
        return axios.post(`${apiUrl}/api/v1/estoques/saldo/`, payload, authHeaders(token));
    },
    async getLivrosAlunosMASTER(token, nomeAlunoResponsavel, dataInicial, dataFinal) {
        let url = `${apiUrl}/api/v1/estoques/livros-alunos-master/`;
        if (nomeAlunoResponsavel) {
            url += `?nome_aluno_responsavel=${nomeAlunoResponsavel}`;
            if (dataInicial) {
                url += `&data_inicial=${dataInicial}`;
            }
            if (dataInicial) {
                url += `&data_final=${dataFinal}`;
            }
        }
        else if (dataInicial) {
            url += `?data_inicial=${dataInicial}`;
            if (dataInicial) {
                url += `&data_final=${dataFinal}`;
            }
        }
        else if (dataInicial) {
            url += `?data_final=${dataFinal}`;
        }
        return axios.get(url, authHeaders(token));
    },
    async utilsImprimirTabela(token, payload) {
        return axios.post(`${apiUrl}/api/v1/utils/imprimir-tabela/`, payload.payload, authHeaders(token));
    },
    async utilsNFSeImportarXML(token, payload) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (payload.files.length > 0) {
            for (const file of payload.files) {
                formData.append('files', file.file, file.file.name);
            }
        }
        formData.append('obj_in', JSON.stringify(payload.data));
        return axios.post(`${apiUrl}/api/v1/utils/nfse/sincronizar/${payload.companyId}/`, formData, headers);
    },
    async getTickets(token) {
        return axios.get(`${apiUrl}/api/v1/tickets/`, authHeaders(token));
    },
    async getTicket(token, id) {
        return axios.get(`${apiUrl}/api/v1/tickets/${id}/`, authHeaders(token));
    },
    async createTicketNote(token, payload) {
        return axios.post(`${apiUrl}/api/v1/tickets/note/`, payload, authHeaders(token));
    },
    async createTicket(token, payload) {
        return axios.post(`${apiUrl}/api/v1/tickets/`, payload, authHeaders(token));
    },
    async getRelatorios(token) {
        return axios.get(`${apiUrl}/api/v1/relatorios/`, authHeaders(token));
    },
    async executeRelatorio(token, id, data) {
        return axios.post(`${apiUrl}/api/v1/relatorios/${id}/`, data, authHeaders(token));
    },
    async getCompeticoes(token) {
        return axios.get(`${apiUrl}/api/v1/competicoes/`, authHeaders(token));
    },
    async getCompeticao(token, id) {
        return axios.get(`${apiUrl}/api/v1/competicoes/${id}/`, authHeaders(token));
    },
    async getCompeticaoEtapas(token, competicaoId) {
        return axios.get(`${apiUrl}/api/v1/competicao-etapas/competicao/${competicaoId}`, authHeaders(token));
    },
    async getCompeticaoEtapa(token, id) {
        return axios.get(`${apiUrl}/api/v1/competicao-etapas/${id}`, authHeaders(token));
    },
    async getNotasFicais(token) {
        return axios.get(`${apiUrl}/api/v1/plugnotas-nfe-troca-devolucao/notas-fiscais/`, authHeaders(token));
    },
    async filtroPorTabelaGet(token, tableName) {
        return axios.get(`${apiUrl}/api/v1/filtro-por-tabela/${tableName}/`, authHeaders(token));
    },
    async filtroPorTabelaCreate(token, payload) {
        return axios.post(`${apiUrl}/api/v1/filtro-por-tabela/`, payload, authHeaders(token));
    },
    // athpme
    async getJWT(token) {
        return axios.get(`${apiUrl}/api/v1/at-home/`, authHeaders(token));
    },
    async getNotificacaoAula(token, user_token) {
        return axios.get(`${apiUrl}/api/v1/at-home/aula/${user_token}/`, authHeaders(token));
    },
    async getAtHomeTurmas(token, somenteDigital) {
        return axios.get(`${apiUrl}/api/v1/at-home/turmas/?somente_digital=${somenteDigital}`, authHeaders(token));
    },
    async getAtHomeLivrosAluno(token) {
        return axios.get(`${apiUrl}/api/v1/at-home/meus-livros/`, authHeaders(token));
    },
    async getAtHomeTurma(token, turmaAlunoId) {
        return axios.get(`${apiUrl}/api/v1/at-home/turma/${turmaAlunoId}/`, authHeaders(token));
    },
    async getAtHomeNotas(token, turmaAlunoId) {
        return axios.get(`${apiUrl}/api/v1/at-home/turma/${turmaAlunoId}/notas/`, authHeaders(token));
    },
    async getAtHomeTurmaAulaHoje(token, turmaId) {
        return axios.get(`${apiUrl}/api/v1/at-home/turma/${turmaId}/aula-hoje/`, authHeaders(token));
    },
    async getAtHomeTurmaAlunoAulaHoje(token, turmaAlunoId) {
        return axios.get(`${apiUrl}/api/v1/at-home/turma-aluno/${turmaAlunoId}/aula-hoje/`, authHeaders(token));
    },
    async getAtHomeNotasAtHome(token, turmaAlunoId) {
        return axios.get(`${apiUrl}/api/v1/at-home/turma-aluno/${turmaAlunoId}/notas/athome/`, authHeaders(token));
    },
    async getAtHomeFinanceiro(token) {
        return axios.get(`${apiUrl}/api/v1/at-home/financeiro/`, authHeaders(token));
    },
    async atHomeScannearQrCode(token, qtCode, codigoProduto) {
        return axios.get(`${apiUrl}/api/v1/at-home/scanear-qr-code/${qtCode}/${codigoProduto}/`, authHeaders(token));
    },
    async getHelpdeskTickets(token) {
        return axios.get(`${apiUrl}/api/v1/helpdesk/tickets/`, authHeaders(token));
    },
    async createHelpdeskTicketLog(token, payload) {
        return axios.post(`${apiUrl}/api/v1/helpdesk/tickets/message/`, payload, authHeaders(token));
    },
    async createHelpdeskTicketLogAnexo(token, payload) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (payload.files.length > 0) {
            for (const file of payload.files) {
                formData.append('files', file.file, file.file.name);
            }
        }
        return axios.post(`${apiUrl}/api/v1/helpdesk/tickets/message/anexo/?ticket_id=${payload.id}`, formData, headers);
    },
    async createHelpdeskIssueGitHub(token, ticketId, milestone) {
        return axios.patch(`${apiUrl}/api/v1/helpdesk/tickets/${ticketId}/issue/tipo/${milestone}/`, {}, authHeaders(token));
    },
    async getHelpdeskTicket(token, id) {
        return axios.get(`${apiUrl}/api/v1/helpdesk/tickets/${id}/`, authHeaders(token));
    },
    async getMyHelpdeskUser(token) {
        return axios.get(`${apiUrl}/api/v1/helpdesk/me/`, authHeaders(token));
    },
    async setHelpdeskClaimTicket(token, payload) {
        return axios.put(`${apiUrl}/api/v1/helpdesk/claim/`, payload, authHeaders(token));
    },
    async updateHelpdeskTicket(token, payload) {
        return axios.put(`${apiUrl}/api/v1/helpdesk/`, payload, authHeaders(token));
    },
    // helpdeskUrl
    async downloadHelpdeskAnexo(token, fileName) {
        const headers = { ...authHeaders(token) };
        // @ts-ignore
        headers.responseType = 'blob';
        return axios.get(`${apiUrl}/api/v1/helpdesk/files/${fileName}/`, headers);
    },
    async createHelpdeskTickets(token, payload) {
        const headers = { ...authHeaders(token) };
        headers.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData();
        if (payload.files.length > 0) {
            for (const file of payload.files) {
                formData.append('files', file.file, file.file.name);
            }
        }
        formData.append('payload', JSON.stringify(payload.data));
        return axios.post(`${apiUrl}/api/v1/helpdesk/tickets/`, formData, headers);
    },
    async updateUnidadesMaster(token) {
        const headers = { ...authHeaders(token) };
        return axios.post(`${apiUrl}/api/v1/utils/master-sincroniza-unidades/`, headers);
    },
    async marketingDigitalAgenciaGetFinanceiro(token) {
        return axios.get(`${apiUrl}/api/v1/marketing-digital/agencia-financeiro/`, authHeaders(token));
    },
    async marketingDigitalFundoGetFinanceiro(token) {
        return axios.get(`${apiUrl}/api/v1/marketing-digital/fundo-financeiro/`, authHeaders(token));
    },
    async marketingDigitalFundoCreateFinanceiro(token, payload) {
        return axios.post(`${apiUrl}/api/v1/marketing-digital/fundo-financeiro/`, { id: 0, valor: payload }, authHeaders(token));
    },
    async marketingDigitalHistorico(token) {
        return axios.get(`${apiUrl}/api/v1/marketing-digital/historico/`, authHeaders(token));
    },
    async getNovidades(token) {
        return axios.get(`${apiUrl}/api/v1/novidades/`, authHeaders(token));
    },
    async createNovidade(token, payload) {
        return axios.post(`${apiUrl}/api/v1/novidades/`, payload, authHeaders(token));
    },
    async updateNovidade(token, id, data) {
        return axios.put(`${apiUrl}/api/v1/novidades/${id}/`, data, authHeaders(token));
    },
    async deleteNovidade(token, id) {
        return axios.delete(`${apiUrl}/api/v1/novidades/${id}/`, authHeaders(token));
    },
    async updateContratoCursoId(token, contratoId, cursoId) {
        return axios.put(`${apiUrl}/api/v1/contratos/${contratoId}/curso?curso_id=${cursoId}`, null, authHeaders(token));
    },
};
